<template>
  <div class="ask_reminder">
    <h1>{{ default_labels.ask_reminder }}</h1>
    <Feedbacks :messages="sessionStore.messages" @close="removeSessionMessage($event)"></Feedbacks>
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />

    <form @submit.prevent="ask_reminder" style="display: flex; margin-top: 2.5em; flex-direction: column">
      <p style="margin-top: 0">{{ default_labels.login_link_explain }}</p>
      <div>
        <label for="name">{{ default_labels.email }}</label>
        <input
          id="name"
          type="text"
          style="margin-left: 1em"
          :placeholder="default_labels.email"
          v-model="email"
          required
        />
      </div>

      <div class="btn-bar" :style="localeStore.flex_row_direction">
        <ButtonWithIndicator type="submit" :show_indicator="loading" class="btn btn-primary">{{
          default_labels.send
        }}</ButtonWithIndicator>
        <button class="btn btn-default" @click="sessionStore.action = 'login'">
          {{ default_labels.return }}
        </button>
      </div>
      <div style="margin-top: 1.5em; text-align: center">
        <a href="" @click.prevent="sessionStore.action = null">{{ default_labels.return }}</a>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useSessionStore } from "@/backend/session";
import { useLocaleStore } from "@/locale/i18n_store";
import get_default_labels from "@/locale/default";
import Feedbacks from "@/components/Feedbacks.vue";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";
import { WSClient, ApiMessage } from "@/backend/WSClient";

const sessionStore = useSessionStore(),
  localeStore = useLocaleStore(),
  default_labels = get_default_labels(),
  messages = ref([] as ApiMessage[]),
  loading = ref(false),
  email = ref(""),
  client = new WSClient(loading, messages);

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};
const removeSessionMessage = function (index: number): void {
  sessionStore.messages.splice(index, 1);
};

async function ask_reminder(): Promise<void> {
  const respCode = await client.queryWs("POST", "/user/remind", null, { email: email.value });

  if (respCode.status === 437) {
    messages.value = [{ level: "error", text: default_labels.value.user_account_not_found } as ApiMessage];
    return;
  }
  if (respCode.status === 438) {
    messages.value = [{ level: "error", text: default_labels.value.error } as ApiMessage];
    return;
  }

  if (respCode.status === 200) {
    messages.value = [{ level: "success", text: default_labels.value.success } as ApiMessage];
    return;
  }
}
</script>

<style scoped>
.ask_reminder {
  display: flex;
  flex-direction: column;
  max-width: 30em;
  margin: 3em auto;
  text-align: center;
}
</style>
