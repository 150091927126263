import { Router, RouteLocationRaw, LocationQueryValue } from "vue-router";
import { useLocaleStore } from "@/locale/i18n_store";
import { SupportedLanguages } from "@/locale/default";
import { computed, ComputedRef } from "vue";
import { WSClient } from "./WSClient";
import { IssueLocalized } from '../types/common';


export function cloneObject(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function wupcase(value: string): string {
  if (!value) {
    return "";
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function elipsis(input: string, max: number): string | undefined {
  if (!input) return undefined;
  if (input.length < max) return input;
  return input.substring(0, max - 3) + "...";
}

export function get_server_url(): string {
  return (import.meta.env.VITE_MAIN_HOST ?? "") + (import.meta.env.VITE_SERVER_BASE ?? "");
}

export function getLocale(): SupportedLanguages {
  const locStore = useLocaleStore();
  return locStore.locale ?? "fr";
}

export function main_site_url_comp(): ComputedRef<string> {
  const main_site_url_scheme = (import.meta.env.VITE_MAIN_HOST ?? "") + (import.meta.env.VITE_PAGE_ROOT ?? "");
  return computed((): string => main_site_url_scheme.replace("{locale}", getLocale()));
}

export function get_api_url(): string {
  return (import.meta.env.VITE_SERVER_BASE || "/") + (import.meta.env.VITE_API || "/api");
}

export function format_date(input: Date | null | undefined | number): string {
  if (input === null || input === undefined) return "";
  const date = typeof input === "number" ? new Date(input * 1000) : input;
  return date.toLocaleDateString(getLocale(), {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

const lt = /<\/?script/g;
export function xss_filter(instr: string): string {
  return instr && instr.replace ? instr.replace(lt, "&lt;script").replace("<!--", "&lt;!--") : instr;
}

export function firstArg(input: string | string[] | LocationQueryValue | LocationQueryValue[]): string | undefined {
  if (!input) return;
  const el = Array.isArray(input) ? input[0] : input;
  return el?.toString();
}

export function positiveInt(input: number | string | undefined): number | undefined {
  if (input === undefined) return undefined;
  if (typeof input === "string") input = parseInt(input);
  return input > 0 ? input : undefined;
}

export function open_tab(router: Router, route: RouteLocationRaw): Window | null {
  const routeData = router.resolve(route);
  console.log(routeData.href);
  return window.open(routeData.href, "_blank");
}

export function current_timestamp(): number {
  const now = new Date();
  return Math.round(now.getTime() / 1000);
}

export type PeriodType = "year" | "quarter" | "month" | "week" | "message_number" | "none";

export function nl2p(input: string | undefined): string {
  if (!input) return "";
  return input
    .split("\n")
    .map((line) => "<p>" + line + "</p>")
    .join("");
}

export function log(): void {
  new WSClient().queryWs("POST", "/log", null, {
    referer: window.location.href,
  });
}

export interface Duration {
  value: number;
  unit: Intl.RelativeTimeFormatUnit;
}

export function hours_to_duration(hours: number): Duration {
  const abs_hours = Math.abs(hours);
  if (abs_hours > 17280) return { value: Math.round(hours / (24 * 365.25)), unit: "year" };
  if (abs_hours > 2160) return { value: Math.round(hours / (24 * 30.44)), unit: "month" };
  if (abs_hours > 672) return { value: Math.round(hours / (24 * 7)), unit: "week" };
  if (abs_hours > 72) return { value: Math.round(hours / 24), unit: "day" };
  return { value: hours, unit: "hour" };
}

export function duration_to_hours(duration: Duration): number {
  if(duration.unit === "day") return duration.value * 24;
  if(duration.unit === "week") return duration.value * 24 * 7;
  if(duration.unit === "month") return duration.value * 24 * 30.44;
  if(duration.unit === "year") return duration.value * 24 * 365.25;
  return duration.value;
}

function rand(): string {
  return Math.random().toString(36).substring(2); // remove `0.`
};

export function token(): string {
  return rand() + rand(); // to make it longer
};

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Function to generate a random consonant
function getRandomConsonant() {
  const consonants = ["b", "c", "d", "f", "g", "h", "j", "k", "l", "m", "n", "p", "q", "r", "s", "t", "v", "w", "x", "y", "z","ch","sh","wh"];
  return consonants[getRandomInt(0, consonants.length - 1)];
}

// Function to generate a random vowel
function getRandomVowel() {
  const vowels = ['a','e','i','o','u','ay','ie','oo','oi','ee','ou','ea','ai','on','an','en','ae'];
  return vowels[getRandomInt(0, vowels.length - 1)];
}

export function get_random_prononcable(min: number) {
  let resp = '';
  while(resp.length < min) resp += (getRandomConsonant() + getRandomVowel());
  return resp;
}

export function outcome_type(issue: IssueLocalized, index: number): string {
  if (issue.final_outcome) {
    if (issue.final_outcome === index) {
      if (issue.forecast_outcome === index) {
        return "success";
      }
      return "correction";
    }
    if (issue.forecast_outcome === index) {
      return "failure";
    } else {
      return "closed";
    }
  }
  if (issue.forecast_outcome === index) {
    return "chosen";
  }

  return "";
};

