<template>
  <form @submit="create_new_locale">
    <h2>{{ default_labels.new_locale }}</h2>
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />
    <div style="display: flex; gap: 1em">
      <LangSelector :lang="locale" @change="locale = $event" />
      <ButtonWithIndicator
        type="submit"
        :show_indicator="loading"
        :disabled="source_locale === locale"
        class="btn btn-default"
        >{{ default_labels.create }}</ButtonWithIndicator
      >
    </div>
  </form>
</template>

<script setup lang="ts">
import LangSelector from "@/components/LangSelector.vue";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";
import { SupportedLanguages } from "@/locale/default";
import Feedbacks from "@/components/Feedbacks.vue";
import { WSClient, ApiMessage } from "@/backend/WSClient";
import { Page } from "@/types/common";
import { ref } from "vue";
import { useLocaleStore } from "@/locale/i18n_store";
import get_default_labels from "@/locale/default";

const props = defineProps<{
  slug: string;
  source_locale: string;
}>();

const emit = defineEmits<{
  (e: "created", value: Page): void;
}>();

const localeStore = useLocaleStore(),
  default_labels = get_default_labels(),
  messages = ref([] as ApiMessage[]),
  loading = ref(false),
  locale = ref(localeStore.locale as SupportedLanguages);

const client = new WSClient(loading, messages);

const removeMessage = (index: number): void => {
  messages.value.splice(index, 1);
};

const create_new_locale = () => {
  try {
    client
      .queryWs<{ page: Page }>("POST", "/page/" + props.slug + "/locale", null, {
        source: props.source_locale,
        target: locale.value,
      })
      .then((resp) => {
        emit("created", resp.json.page);
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};
</script>

<style scoped></style>
