<template>
  <div class="home">
    <SearchBox style="margin: 3rem 0" :value="search_string" @change="do_search"/>
    <Feedbacks
      v-if="messages && messages.length > 0"
      :messages="messages"
      @close="removeMessage($event)"
    />
    <div id="issue_list">
      <IssueItem
        v-for="(issue, index) in issues"
        :key="index"
        :issue="issue"
        @search="search($event as string)"
        @open="opened_issue = index"
        @close="opened_issue = -1"
        :opened="opened_issue === index"
        v-show="opened_issue === -1 || opened_issue === index"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import SearchBox from "@/components/SearchBox.vue";
import IssueItem from "@/components/IssueItem.vue";
import Feedbacks from "@/components/Feedbacks.vue";
import { ref, watch, onMounted } from 'vue';
import {WSClient, ApiMessage} from "@/backend/WSClient";
import { SupportedLanguages } from "@/locale/default";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore } from "@/backend/session";
import { IssueLocalized } from "@/types/common";
import { useRoute } from "vue-router";
import { firstArg } from '../../backend/utils';

const issues = ref([] as IssueLocalized[]),
  opened_issue = ref(-1),
  loading = ref(),
  messages = ref([] as ApiMessage[]),
  client = new WSClient(loading, messages),
  localeStore = useLocaleStore(),
  route = useRoute(),
  search_string = ref<string|undefined>();

const search = function (searchString: string | undefined) {
  opened_issue.value = -1;
  client.queryWs<{issues: IssueLocalized[]}>(
      "GET",
      "/issue?lang=" +
      localeStore.locale +
        (searchString ? "&tags=" + searchString : "")
    ).then((apiResp) => {
      issues.value = apiResp.json.issues as IssueLocalized[];
    });
};

const do_search = function (search_val: string | undefined): void {
  search(search_val);
  search_string.value = search_val;
};

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const sessionStore = useSessionStore();
sessionStore.require_no_session();

watch(
  () => firstArg(route.query.tag),
  (tag: string | undefined) => {
    search_string.value = tag;
    do_search(tag);
  }
);

watch(
  () => firstArg(route.query.lang),
  (lang: string | undefined) => {
    if(lang) {
      localeStore.locale = lang as SupportedLanguages;
      do_search(search_string.value);
    }
  },
);

onMounted(()=>{
  const tag = firstArg(route.query.tag);
  if(tag)
    search_string.value = tag;
  const lang = firstArg(route.query.lang);
  if(lang)
    localeStore.locale = lang as SupportedLanguages;
  do_search(tag);
})

</script>

<style scoped>

#issue_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}
</style>
