<template>
  <div class="profile">
    <h1>{{ default_labels.user_edit }}</h1>
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />
    <p style="margin-top: 0">{{ default_labels.edit_profile }}</p>
    <form @submit.prevent="update_profile" ref="form" style="margin-bottom: 4rem">
      <label for="name">{{ default_labels.display_name }}</label>
      <input id="name" type="text" :placeholder="default_labels.display_name" v-model="user_name" required />
      <label for="email">{{ default_labels.email }}</label>
      <input id="email" type="text" :placeholder="default_labels.email" v-model="user_email" required />
      <label for="email">{{ default_labels.language }}</label>
      <LangSelector :lang="localeStore.locale" @change="change_locale" />
      <div style="grid-column: 1 / span 2; text-align: center; margin-bottom: 4rem">
        <input class="btn btn-default with-indicator" type="submit" :value="default_labels.save" />
        <Loader v-show="loading" class="ajax-indicator" color="white" />
      </div>

      <label>{{ default_labels.secret_hash }}</label>
      <a download="ksr.txt" :href="token_url" target="_blank">{{ default_labels.download }}</a>
    </form>
    <div style="text-align: center; margin-bottom: 4rem">
      <button class="btn btn-default" @click.prevent="logout">
        {{ default_labels.logout }}
      </button>
    </div>
    <div style="text-align: center; margin-bottom: 4rem">
      <router-link to="/">
        {{ default_labels.return }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LangSelector from "@/components/LangSelector.vue";
import Feedbacks from "@/components/Feedbacks.vue";
import Loader from "@/components/Loader.vue";
import { SupportedLanguages } from "@/locale/default";
import { WSClient, ApiMessage } from "@/backend/WSClient";
import { User } from "@/types/common";
import { computed, ref } from "vue";
import { useSessionStore } from "@/backend/session";
import { useLocaleStore } from "@/locale/i18n_store";
import get_default_labels from "@/locale/default";
import router from "@/router";

const sessionStore = useSessionStore(),
  localeStore = useLocaleStore(),
  default_labels = get_default_labels(),
  messages = ref([] as ApiMessage[]),
  loading = ref(false),
  client = new WSClient(loading, messages);

let logged_user = sessionStore.logged_user as User;

const user_name = ref((logged_user && logged_user.name)),
  user_email = ref((logged_user && logged_user.email)),
  user_locale = ref((logged_user && logged_user.locale));

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const token_url = computed((): string => {
  if (sessionStore.token === null) return "";
  return "data:plain/text," + encodeURIComponent(sessionStore.token);
});

const change_locale = function (val: SupportedLanguages): void {
  user_locale.value = val;
};

const update_profile = async () => {
  await client.queryWs<{}>("POST", "/user", null, {
    locale: user_locale.value || localeStore.locale,
    name: user_name.value,
    email: user_email.value,
  });

  if (client.last_status !== 200) return;
  sessionStore.logged_user = { name: user_name.value, email: user_email.value } as User;
  messages.value = [{level: "info", text: default_labels.value.success}];
};

function logout(): void {
  sessionStore.logout();
  router.push({ name: "Home" });
}

sessionStore.require_session();
</script>

<style>
.profile {
  display: flex;
  flex-direction: column;
  max-width: 30em;
  margin: 3em auto;
}

.profile form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  align-items: center;
}

.profile label {
  padding: 0.75em 0;
}
</style>
