<template>
  <div class="box issue-item">
    <div class="tags">
      <a class="tag" v-for="(tag, index) in tag_list" :key="index" :href="'/?tag=' + tag"
        >#{{ tag }}</a
      >
    </div>
    <h1>
      <router-link :to="'/issue/' + issue.slug + '/status'">{{ issue.title }}</router-link>
    </h1>
    <div class="btn-bar">
      <ButtonWithIndicator
        v-if="issue.status_id"
        class="btn btn-primary"
        :show_indicator="loading"
        @click.prevent="change_status(0)"
        >{{ default_labels.withdraw }}</ButtonWithIndicator
      >
      <ButtonWithIndicator v-else class="btn btn-default" :show_indicator="loading" @click.prevent="change_status(1)">{{
        default_labels.publish
      }}</ButtonWithIndicator>
      <router-link
        class="btn btn-default"
        :to="{ name: 'IssueEditor', params: { id: issue.issue_id }, query: { lang: localeStore.locale } }"
        >{{ default_labels.modify }}</router-link
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { IssueLocalized } from "@/types/common";
import get_default_labels from "@/locale/default";
import { ApiMessage, WSClient } from "../backend/WSClient";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";
import { useLocaleStore } from "@/locale/i18n_store";

const props = defineProps<{
  issue: IssueLocalized;
  forecast?: IssueLocalized;
}>();

const emit = defineEmits<{
  (e: "search", value: string): void;
  (e: "status_id_changed", value: number): void;
}>();

const loading = ref(false),
  messages = ref<ApiMessage[]>([]),
  client = new WSClient(),
  localeStore = useLocaleStore();

const tag_list = computed((): string[] => {
  const tl = props?.issue?.tags;
  if (!tl) return [];
  return tl.split(" ");
});

const default_labels = get_default_labels();

const change_status = (status: number) => {
  client.queryWs<{}>("PUT", "/issue/" + props.issue.issue_id + '/status/' + status,).then((res) => {
    if (res.status === 200) emit("status_id_changed", status);
  });
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.issue-item {
  margin-bottom: 3rem;
  background-size: 100%;
}

.issue-item .outcome {
  margin: 0 auto 1.5em;
  width: 100%;
}

.issue-item .outcome .sub {
  font-size: 0.8em;
}

.issue-item button {
  margin: auto 0;
  cursor: pointer;
}

.issue-item a {
  text-decoration: none;
}

.issue-item a:hover {
  text-decoration: underline;
}
</style>
