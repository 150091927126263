<template>
  <div :class="is_closed ? 'issue closed' : 'issue'">
    <Popup
      v-if="show_issue_confirmation"
      @close="show_issue_confirmation = false"
      :window_style="issue_window_style"
      title=""
    >
      <p>{{ default_labels.issue_confirmation }}</p>
      <button class="btn" @click.prevent="show_issue_confirmation = false">
        {{ default_labels.return }}
      </button>
    </Popup>
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />
    <Loader class="loader" width="1.5rem" v-show="loading"></Loader>
    <div class="box issue-item">
      <font-awesome-icon class="share" icon="share-alt" @click.prevent="share" />
      <div class="tags">
        <a class="tag" v-for="(tag, index) in tag_list" :key="index" :href="'/?tag=' + tag"
          >#{{ tag }}</a
        >
      </div>
      <h1>{{ issue.title }}</h1>
      <div v-html="description_formated"></div>
      <p>{{ forecast_instruction }}</p>
      <h2>{{ default_labels.your_forecast }}</h2>
      <p v-if="is_success" class="result right">{{ default_labels.you_were_right }}</p>
      <p v-if="is_success === false" class="result wrong">{{ default_labels.you_were_wrong }}</p>
      <div class="certificate-menu">
        <button v-if="is_success" class="btn btn-default" @click.prevent="toggle_certificate">
          {{ default_labels.certificate_title }}
        </button>
        <font-awesome-icon
          icon="copy"
          class="btn btn-default"
          v-if="certificate_shown"
          @click.prevent="copy_certificate"
        />
        <font-awesome-icon
          icon="share-alt"
          class="btn btn-default"
          v-if="certificate_shown"
          @click.prevent="share_certificate"
        />
      </div>
      <pre v-if="certificate_shown">{{ certificate }}</pre>
      <div class="body">
        <OutcomeList :issue="issue" :is_closed="is_closed" :choosed_outcome="issue.forecast_outcome" @choose_outcome="choose_outcome"></OutcomeList>
        <IssueSummaryChart v-if="issue.issue_id" :counts="chart_data" :labels="chart_labels" :colors="chart_colors" />
      </div>
      <div class="btn-bar">
        <button class="btn" @click.prevent="$router.go(-1)">
          {{ default_labels.return }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nl2p, hours_to_duration } from "@/backend/utils";
import Feedbacks from "@/components/Feedbacks.vue";
import Loader from "@/components/Loader.vue";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { WSClient, ApiMessage } from "@/backend/WSClient";
import IssueSummaryChart from "@/components/IssueSummaryChart.vue";
import { Outcome, OutcomeStat } from "@/types/common";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore, SessionStatus } from "@/backend/session";
import { firstArg } from "@/backend/utils";
import { useRoute } from "vue-router";
import { computed, ref, watch, CSSProperties, inject, onMounted } from "vue";
import get_default_labels from "@/locale/default";
import Popup from "@/components/Popup.vue";
import { IssueLocalized } from "@/types/common";
import { SupportedLanguages } from "@/locale/default";
import OutcomeList from "./OutcomeList.vue";
import { defined_colors, outcome_short_label } from "@/backend/outcome";

const issue = ref({
    description: "",
    title: "",
  } as IssueLocalized),
  show_issue_confirmation = ref(false),
  loading = ref(false),
  messages = ref([] as ApiMessage[]),
  route = useRoute(),
  localeStore = useLocaleStore(),
  sessionStore = useSessionStore(),
  outcome_stat = ref([] as OutcomeStat[]),
  default_labels = get_default_labels(),
  client = new WSClient(loading, messages),
  mixpanel = inject("mixpanel") as any,
  show_certificate = ref(false),
  certificate = ref<string | undefined>(),
  relative_datetime_format = localeStore.relative_datetime_format;

const load_issue = (slugs: string | string[]): Promise<IssueLocalized | null> => {
  const slug = slugs instanceof Array ? slugs[0] : slugs;

  return client
    .queryWs<{ issue: IssueLocalized }>("GET", "/issue/" + slug + "?lang=" + (url_lang.value ?? localeStore.locale))
    .then((resp): IssueLocalized | null => {
      return resp.json.issue ?? null;
    });
};

const load_stat = async function (issue_id: number) {
  return await client
    .queryWs<{ outcomes: OutcomeStat[] }>("GET", "/issue/" + issue_id + "/forecast_summary")
    .then((apiResp) => {
      outcome_stat.value = apiResp.json.outcomes;
    });
};

const load_all = function (slug: string | undefined): void {
  if (slug === undefined) return;
  load_issue(slug).then((issue_ret) => {
    if (issue_ret === null || !issue_ret?.issue_id) return;
    issue.value = issue_ret;
    document.querySelectorAll('meta[name^="og:"').forEach((n) => {
      n.remove();
    });
    load_stat(issue_ret.issue_id);
    mixpanel.track("show_issue", { slug: slug });
  });
};

const description_formated = computed((): string => {
  return nl2p(issue.value.description);
});

const chart_data = computed((): number[] => {
  if (outcome_stat.value.length === 0) return [];
  const issue_nb = issue.value.outcomes.length;
  let rep = Array(issue_nb).fill(0) as number[];
  for (let index = 0; index < issue_nb; index++) {
    const os = outcome_stat.value[index] as OutcomeStat;
    const ot = issue.value.outcomes[index] as Outcome;
    let total = 0;
    if(os?.count){
      total = os.count;
    } 
    if(ot?.offset){
      total = total + ot.offset;
    } 
    rep[index] = total;
  }
  return rep as number[];
});

const chart_labels = computed((): string[] => {
  return issue.value.outcomes.map((outcome: Outcome) => {
    return outcome_short_label(outcome, relative_datetime_format);
  });
});

const chart_colors = computed((): string[] => {
  return defined_colors.slice(0, chart_data.value.length);
});

const relative_format = computed((): Intl.RelativeTimeFormat => {
  return new Intl.RelativeTimeFormat(localeStore.locale, { style: "long" });
});

const forecast_instruction = computed((): string => {
  const str = default_labels.value.forecast_with_advance;
  const duration = hours_to_duration(issue.value.delay / 3600);
  const duration_nb = isNaN(duration.value) ? "NaN" : relative_format.value.format(duration.value, duration.unit);
  return str.replace("{0}", duration_nb);
});

const tag_list = computed((): string[] => {
  const tl = issue.value?.tags;
  if (!tl) return [];
  return tl.split(" ");
});

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const choose_outcome = async function (index: number): Promise<void> {
  let user_token = sessionStore.token;
  if (sessionStore.connected === SessionStatus.Unauthenticated) {
    mixpanel.track("auto_register");
    user_token = await sessionStore.auto_register();
  } else if (sessionStore.connected === SessionStatus.Broken) {
    sessionStore.require_session();
  }
  mixpanel.track("choose_outcome");
  await new WSClient(undefined, messages)
    .queryWs<{}>("POST", "/forecast", null, {
      user_key: user_token,
      issue_id: issue.value.issue_id,
      outcome: index,
      locale: localeStore.locale,
    })
    .then(() => {
      mixpanel.track("choose_outcome_ok");
      if (issue.value.slug) load_all(issue.value.slug);
      show_issue_confirmation.value = true;
    });
};

const is_closed = computed((): boolean => {
  const fo = issue.value?.final_outcome;
  return fo !== null && fo !== undefined;
});

const is_success = computed((): boolean | undefined => {
  const fo = issue.value.final_outcome;
  if (fo === null || fo === undefined) return undefined;
  return fo === issue.value.forecast_outcome;
});

const certificate_shown = computed(() => {
  return show_certificate.value && certificate.value != undefined;
});

const issue_window_style = { "max-width": "60em" } as CSSProperties;

const toggle_certificate = async () => {
  if (!show_certificate.value && certificate.value === undefined) {
    new WSClient(loading, messages)
      .queryJson("GET", "/api/forecast/" + issue.value.issue_id + "/cert?lang=" + localeStore.locale, null)
      .then((rest) => {
        certificate.value = rest.text;
      });
    show_certificate.value = !show_certificate.value;
  }
};

const share = (): void => {
  const iss = issue.value;
  if (iss === null || !iss.issue_id) return;

  if (navigator.share) {
    navigator
      .share({
        title: iss.title,
        text: iss.description,
        url: window.location.href + window.location.search,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    toClipboard(window.location.href + window.location.search).then(() => {
      toast(default_labels.value.copied, {
        autoClose: 1000,
      });
    });
  }
};

const share_certificate = (): void => {
  const iss = issue.value;
  if (iss === null || !iss.issue_id) return;

  if (navigator.share) {
    navigator
      .share({
        title: iss.title,
        text: certificate.value,
        url: window.location.href + window.location.search,
      })
      .then(() => console.log("Successful certificate share"))
      .catch((error) => console.log("Error sharing certificate", error));
  } else {
    toClipboard(window.location.href + window.location.search).then(() => {
      toast(default_labels.value.copied, {
        autoClose: 1000,
      });
    });
  }
};

const copy_certificate = (): void => {
  if (certificate.value === undefined) return;
  toClipboard(certificate.value);
  toast(default_labels.value.copied, {
    autoClose: 1000,
  });
};

const url_lang = computed((): string | undefined => {
  return firstArg(route.query.lang);
});

watch(
  () => route.params.slug,
  (slug) => {
    load_all(firstArg(slug));
  }
);

watch(
  () => firstArg(route.query.lang),
  (lang: string | undefined) => {
    if(lang) {
      localeStore.locale = lang as SupportedLanguages;
      load_all(issue.value.slug);
    }
  }
);

onMounted(()=>{
  const lang = firstArg(route.query.lang);
  if(lang)
    localeStore.locale = lang as SupportedLanguages;
  load_all(firstArg(route.params.slug));
});

sessionStore.require_no_session();
</script>

<style scoped>
.issue {
  margin-top: 3rem;
  position: relative;
}

.share {
  position: absolute;
  right: 1.5em;
  font-size: 1.6em;
  cursor: pointer;
}

.issue .loader {
  position: absolute;
  right: 4rem;
  top: 2rem;
  width: 1.5rem;
}

.issue .body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}

.result {
  font-size: 1.3em;
  font-weight: bold;
}

.right {
  color: var(--green-medium);
}

.wrong {
  color: var(--fuschia-79);
}

.certificate-menu {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 1.5em;
}

.certificate-menu svg.btn {
  font-size: 1.1em;
  padding: 0.7em 1em;
}
</style>
