import { defineStore } from 'pinia';
import { Translations, SupportedLanguages, LocKey } from "@/locale/default";
import { computed, ComputedRef, CSSProperties } from "vue";

function get_browser_lang(): SupportedLanguages | null {
  const supp = ["en", "fr", "ru", "uk", "cn"];
  for (let index = 0; index < navigator.language.length; index++) {
    const locale = navigator.language[index];
    const lang = locale.split("_")[0];
    if (supp.includes(lang)) return lang as SupportedLanguages;
  }
  return null;
}

export interface LocaleState {
  locale: SupportedLanguages;
  i18n: Record<string, Translations>;
  i18n_promise: Record<string, Promise<Translations>>;
}

export const useLocaleStore = defineStore('locale', {
  state: (): LocaleState => ({
    locale: get_browser_lang() ?? 'en',
    i18n: {} as Record<string, Translations>,
    i18n_promise: {} as Record<string, Promise<Translations>>,
  }),
  getters: {
    main_site_url(state): string {
      const validationUrl =
        (import.meta.env.VITE_MAIN_HOST ?? "") +
        (import.meta.env.VITE_PAGE_ROOT ?? "");
      return validationUrl.replace("{locale}", state.locale);
    },
    
    direction(state): string | undefined {
      return ["ar","fa","he"].includes(state.locale)  ? "rtl" : undefined;
    },

    flex_row_direction(state): CSSProperties {
      return {"flex-direction": ["ar","fa","he"].includes(state.locale)  ? "row-reverse" : "row"};
    },

    datetime_format(state): Intl.DateTimeFormat {
      return new Intl.DateTimeFormat(state.locale, {
        dateStyle: "full",
        timeStyle: "long",
      } as Intl.DateTimeFormatOptions);
    },

    date_format(state): Intl.DateTimeFormat {
      return new Intl.DateTimeFormat(state.locale, {
        dateStyle: "full"
      } as Intl.DateTimeFormatOptions);
    },
    
    relative_datetime_format(state): Intl.RelativeTimeFormat {
      return new Intl.RelativeTimeFormat(state.locale, {
        numeric: "auto",
      } as Intl.RelativeTimeFormatOptions);
    }
  },
  actions: {
    set_loc_section(key: string, content: Translations, reset_promise: boolean) {
      console.log("Set loc session " + key + " " + content);
      this.i18n[key] = content;
      if (reset_promise) {
        delete this.i18n_promise[key];
      }
    },

    set_locale(locale: SupportedLanguages) {
      if(!locale) locale = 'fr';
      console.log("Set locale", locale);
      if (this.locale === locale) return;
        this.locale = locale;
      this.i18n_promise = {} as Record<string, Promise<Translations>>;
    },

    async load_locale(key: LocKey): Promise<Translations> {
      const loc_key = key + "_" + this.locale;
      console.log("get loc session", loc_key);
      if (!(typeof this.i18n[loc_key] === "undefined"))
        return this.i18n[loc_key];

      const loc = await import(`@/locale/lang/${loc_key}.ts`);
      console.log("i18n loaded", loc_key);
      const content = (loc[key] || loc.default) as Translations;
      this.set_loc_section(loc_key, content, true);
      return content;
    },

    getLoadRef(key: LocKey, default_translation: Translations): ComputedRef<Translations> {
      return computed(() =>{
        const loc_key = key + "_" + this.locale;
        if(this.i18n[loc_key])
         return this.i18n[loc_key];
        this.load_locale(key);
        return default_translation;
      });
    }
  }
});

