<template>
  <div>
    <h1>Translations</h1>
    <div style="display: flex; margin: 1em 0; gap: 1em">
      <LangSelector :lang="current_lang" @change="current_lang = $event" />
      <button @click="translate">Start</button>
    </div>
    <table>
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, propertyName, index) in translated">
          <td>{{ propertyName }}</td>
          <td>{{ value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { SupportedLanguages } from "@/locale/default";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore } from "@/backend/session";
import { DefaultLabels } from "@/locale/default";
import LangSelector from "@/components/LangSelector.vue";

const localeStore = useLocaleStore();
const sessionStore = useSessionStore();
sessionStore.require_session();

const current_lang = ref<SupportedLanguages>("fr"),
  translated = ref<Record<string, string>>({});

const get_lang_translation = async (loc: SupportedLanguages) => {
  const mod = await import(`@/locale/lang/default_${loc}`);
  return mod.default;
};

const loop = (english: DefaultLabels, target: DefaultLabels) => {
  for (const [key, value] of Object.entries(english)) {
    if (target[key]) {
      if (target[key] === value) {
        console.log(`Identical ${key}: ${value}`);
      } else {
        console.log(`Ok ${key}`);
      }
    } else {
      console.log(`Missing ${key}: ${value}`);
    }
    translated.value[key] = target[key];
  }
};

const translation_keys = computed(() => {
  return Object.keys(translated.value);
});

const translate = async () => {
  console.log("Start translate");
  const english = await get_lang_translation("en");
  const target = await get_lang_translation(current_lang.value);
  loop(english, target);
};

const translate_input = async () => {
  console.log("Start translate");
  const english = await get_lang_translation("en");
  const target = await get_lang_translation(current_lang.value);
  loop(english, target);
};

</script>

<style scoped>
table {
  border-collapse: collapse;
}

table td,
table th {
  border: 1px solid white;
}
</style>
