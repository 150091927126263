<template>
  <div class="home">
    <h1>{{ default_labels.my_forecasts }}</h1>
    <SearchBox style="margin: 3rem 0" @change="search($event)" />
    <Feedbacks
      v-if="messages && messages.length > 0"
      :messages="messages"
      @close="removeMessage($event)"
    />
    <div id="forecast_list">
      <IssueItem
        v-for="(issue, index) in issues"
        :key="index"
        :issue="issue"
        @search="search($event)"
        @open="opened_issue = index"
        @close="opened_issue = -1"
        :opened="opened_issue === index"
        v-show="opened_issue === -1 || opened_issue === index"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import SearchBox from "@/components/SearchBox.vue";
import IssueItem from "@/components/IssueItem.vue";
import Feedbacks from "@/components/Feedbacks.vue";
import {
  WSClient,
  ApiMessage,
} from "@/backend/WSClient";
import { ref, watch } from "vue";
import { IssueLocalized } from "@/types/common";
import { useLocaleStore } from "@/locale/i18n_store";
import get_default_labels from "@/locale/default";

const issues = ref([] as IssueLocalized[]),
  opened_issue = ref(-1),
  loading = ref(),
  messages = ref([] as ApiMessage[]),
  client = new WSClient(loading, messages);

const localeStore = useLocaleStore();

const search = function (searchString: string | undefined) {
  opened_issue.value = -1;
  const tagsearch =
    searchString === undefined ? "" : (("?tags=" + searchString) as string);
    client.queryWs<{issues: IssueLocalized[]}>("GET", "/forecast/my" + tagsearch)
    .then((apiResp) => {
      issues.value = apiResp.json.issues as IssueLocalized[];
    });
};

search(undefined);

watch(
  () => localeStore.locale,
  (newLocale: string) => {
    search(undefined);
  },
  { immediate: true }
);
const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const default_labels = get_default_labels();
</script>

<style scoped>
#forecast_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}
</style>
