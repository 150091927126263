import { computed, ComputedRef } from "vue";
import { Translations, loadLocale } from "./default";
import default_loc from "./lang/richtexteditor_fr";

export interface RichTextEditorLabel extends Translations {
  edit_source: string;
  strong: string;
  emphasize: string;
  del: string;
  underline: string;
  justify_left: string;
  justify_right: string;
  justify_center: string;
  remove_formating: string;
}

export default function get(): ComputedRef<RichTextEditorLabel> {
  const fn = (): RichTextEditorLabel => {
    return loadLocale("richtexteditor", default_loc) as RichTextEditorLabel;
  };
  return computed(fn);
}
