<template>
  <form @submit.prevent="login">
    <h1>{{ default_labels.login }}</h1>
    <Feedbacks :messages="sessionStore.messages" @close="removeSessionMessage($event)"></Feedbacks>
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />
    <div style="display: flex; flex-direction: column">
      <label for="name">{{ default_labels.secret_hash }}</label>
      <input
        id="secret_key"
        type="text"
        :placeholder="default_labels.paste_here"
        v-model="secret_hash"
        required
        style="min-height: 4em; vertical-align: top"
      />
    </div>
    <div class="btn-bar" :style="localeStore.flex_row_direction">
      <ButtonWithIndicator type="submit" :show_indicator="loading" class="btn btn-primary">{{
        default_labels.login
      }}</ButtonWithIndicator>
      <button class="btn btn-default" @click="sessionStore.action = 'register'">
        {{ default_labels.register }}
      </button>
      <button class="btn btn-default" @click="sessionStore.action = 'remind'">
        {{ default_labels.ask_reminder }}
      </button>
    </div>
    <div style="margin-top: 1.5em; text-align: center">
      <a href="" @click.prevent="sessionStore.action = null">{{default_labels.return }}</a>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { WSClient, ApiMessage } from "@/backend/WSClient";
import { User } from "@/types/common";
import { ref } from "vue";
import { useSessionStore, SessionAction } from "@/backend/session";
import { useLocaleStore } from "@/locale/i18n_store";
import get_default_labels from "@/locale/default";
import { SupportedLanguages } from "@/locale/default";
import Feedbacks from "@/components/Feedbacks.vue";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";

const sessionStore = useSessionStore();
const localeStore = useLocaleStore();
const default_labels = get_default_labels();

const secret_hash = ref(""),
  loading = ref(false),
  messages = ref([] as ApiMessage[]),
  client = new WSClient(loading, messages);

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};
const removeSessionMessage = function (index: number): void {
  sessionStore.messages.splice(index, 1);
};

function login(): void {
  client
    .queryWs<{ name: string; connection_token: string; email: string; locale: SupportedLanguages }>(
      "GET",
      "/user?token=" + secret_hash.value
    )
    .then((apiResp) => {
      if (apiResp.status != 200) return;
      localeStore.locale = apiResp.json.locale;
      sessionStore.auth_success({ name: apiResp.json.name, email: apiResp.json.email } as User, apiResp.json.connection_token);
      sessionStore.messages = [{ level: "info", text: default_labels.value.welcome_back.replace("{0}", apiResp.json.name) }];
    })
    .catch((err) => {
      const text = err === 404 ? default_labels.value.user_account_not_found : default_labels.value.error;
      messages.value.push({ level: "error", text: text } as ApiMessage);
    });
}
</script>
