import { ApiMessage, WSClient } from "./WSClient";
import { User } from "@/types/common";
import { defineStore } from "pinia";
import { useLocaleStore } from "@/locale/i18n_store";
import { RouteLocationNormalizedLoaded } from "vue-router";
import router from "@/router/index";
import { get_random_prononcable } from "@/backend/utils";
import { inject } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";
import get_default_labels from "@/locale/default";

export type SessionAction = "login" | "register" | "remind";

export enum SessionStatus {
  Authenticated = "auth",
  Anonymous = "anon",
  Unauthenticated = "unauth",
  Broken = "broken",
}

export interface SessionState {
  logged_user: User | null;
  status: string | null;
  token: string | null;
  preferences: Record<string, unknown>;
  action: SessionAction | null;
  after_auth_route?: RouteLocationNormalizedLoaded;
  messages: ApiMessage[];
}

export const useSessionStore = defineStore("session", {
  state: (): SessionState => ({
    logged_user: {} as User,
    status: null,
    token: null,
    action: null,
    preferences: {} as Record<string, any>,
    after_auth_route: undefined,
    messages: [],
  }),
  getters: {
    connected(state): SessionStatus {
      if (!!state.token) {
        if (state.logged_user?.email) return SessionStatus.Authenticated;
        return SessionStatus.Anonymous;
      }
      if (state.logged_user?.email) return SessionStatus.Broken;
      return SessionStatus.Unauthenticated;
    },
    user_initial(state): string | undefined{
      if (this.connected === SessionStatus.Anonymous) return '??';
      if (this.connected === SessionStatus.Broken) return '!!';
      const n = state.logged_user?.name;
      if (!n) return undefined;
      const wds = n.split(" ");
      return wds.length === 1 ? n.substring(0, 2) : wds[0].charAt(0) + wds[0].charAt(1);
    },
  },
  actions: {
    require_session() {
      if (!this.token) this.action = "login";
    },
    require_no_session() {
      this.action = null;
    },
    auth_success(user: User, token: string) {
      this.token = token;
      this.logged_user = user;
      const next_route = this.after_auth_route;
      this.action = null;
      if (next_route) {
        router.push(next_route);
        this.after_auth_route = undefined;
      }
    },
    logout() {
      this.logged_user = null;
      this.token = null;
    },
    auth_error(after: RouteLocationNormalizedLoaded) {
      this.logout();
      this.after_auth_route = after;
      router.push({ name: "Login", params: { error: 400 } });
    },
    async auto_register(): Promise<string> {
      const client = new WSClient(undefined),
        localeStore = useLocaleStore();

      const rep = await client
        .queryWs<{ token: string }>("POST", "/register", null, {
          locale: localeStore.locale || "en",
          name: "Anonymous-" + get_random_prononcable(4),
        });
      this.token = rep.json.token;
      return rep.json.token;
    },
  },
  persist: true,
});
