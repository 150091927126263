<template>
  <div class="richtext-editor">
    <div class="menu" v-if="show_menu">
      <div class="button-group">
        <button
          class="see_html"
          :title="labels.edit_source"
          tabindex="-1"
          @click.prevent="toggle_edit_source"
        >
          <svg viewBox="0 0 72 72">
            <path
              fill="none"
              stroke="currentColor"
              stroke-width="8"
              stroke-miterlimit="10"
              d="M26.9 17.9L9 36.2 26.9 54M45 54l17.9-18.3L45 17.9"
            ></path>
          </svg>
        </button>
      </div>
      <div class="button-group">
        <button
          class="strong"
          :title="labels.strong"
          tabindex="-1"
          @click.prevent="basic_suround_selection('strong')"
        >
          <svg viewBox="0 0 72 72">
            <path
              d="M51.1 37.8c-1.1-1.4-2.5-2.5-4.2-3.3 1.2-.8 2.1-1.8 2.8-3 1-1.6 1.5-3.5 1.5-5.3 0-2-.6-4-1.7-5.8-1.1-1.8-2.8-3.2-4.8-4.1-2-.9-4.6-1.3-7.8-1.3h-16v42h16.3c2.6 0 4.8-.2 6.7-.7 1.9-.5 3.4-1.2 4.7-2.1 1.3-1 2.4-2.4 3.2-4.1.9-1.7 1.3-3.6 1.3-5.7.2-2.5-.5-4.7-2-6.6zM40.8 50.2c-.6.1-1.8.2-3.4.2h-9V38.5h8.3c2.5 0 4.4.2 5.6.6 1.2.4 2 1 2.7 2 .6.9 1 2 1 3.3 0 1.1-.2 2.1-.7 2.9-.5.9-1 1.5-1.7 1.9-.8.4-1.7.8-2.8 1zm2.6-20.4c-.5.7-1.3 1.3-2.5 1.6-.8.3-2.5.4-4.8.4h-7.7V21.6h7.1c1.4 0 2.6 0 3.6.1s1.7.2 2.2.4c1 .3 1.7.8 2.2 1.7.5.9.8 1.8.8 3-.1 1.3-.4 2.2-.9 3z"
            ></path>
          </svg></button
        ><button
          class="emphase"
          :title="labels.emphasize"
          tabindex="-1"
          @click.prevent="basic_suround_selection('em')"
        >
          <svg viewBox="0 0 72 72">
            <path d="M26 57l10.1-42h7.2L33.2 57H26z"></path>
          </svg></button
        ><button
          class="del"
          :title="labels.del"
          tabindex="-1"
          @click.prevent="basic_suround_selection('del')"
        >
          <svg viewBox="0 0 72 72">
            <path
              d="M45.8 45c0 1-.3 1.9-.9 2.8-.6.9-1.6 1.6-3 2.1s-3.1.8-5 .8c-2.1 0-4-.4-5.7-1.1-1.7-.7-2.9-1.7-3.6-2.7-.8-1.1-1.3-2.6-1.5-4.5l-.1-.8-6.7.6v.9c.1 2.8.9 5.4 2.3 7.6 1.5 2.3 3.5 4 6.1 5.1 2.6 1.1 5.7 1.6 9.4 1.6 2.9 0 5.6-.5 8-1.6 2.4-1.1 4.3-2.7 5.6-4.7 1.3-2 2-4.2 2-6.5 0-1.6-.3-3.1-.9-4.5l-.2-.6H44c0 .1 1.8 2.3 1.8 5.5zM29 28.9c-.8-.8-1.2-1.7-1.2-2.9 0-.7.1-1.3.4-1.9.3-.6.7-1.1 1.4-1.6.6-.5 1.4-.9 2.5-1.1 1.1-.3 2.4-.4 3.9-.4 2.9 0 5 .6 6.3 1.7 1.3 1.1 2.1 2.7 2.4 5.1l.1.9 6.8-.5v-.9c-.1-2.5-.8-4.7-2.1-6.7s-3.2-3.5-5.6-4.5c-2.4-1-5.1-1.5-8.1-1.5-2.8 0-5.3.5-7.6 1.4-2.3 1-4.2 2.4-5.4 4.3-1.2 1.9-1.9 3.9-1.9 6.1 0 1.7.4 3.4 1.2 4.9l.3.5h11.8c-2.3-.9-3.9-1.7-5.2-2.9zm13.3-6.2zM22.7 20.3zM13 34.1h46.1v3.4H13z"
            ></path>
          </svg></button
        ><button
          class="underline"
          :title="labels.underline"
          tabindex="-1"
          @click.prevent="basic_suround_selection('u')"
        >
          <svg viewBox="0 0 72 72">
            <path
              d="M36 35zM15.2 55.9h41.6V59H15.2zM21.1 13.9h6.4v21.2c0 1.2.1 2.5.2 3.7.1 1.3.5 2.4 1 3.4.6 1 1.4 1.8 2.6 2.5 1.1.6 2.7 1 4.8 1 2.1 0 3.7-.3 4.8-1 1.1-.6 2-1.5 2.6-2.5.6-1 .9-2.1 1-3.4.1-1.3.2-2.5.2-3.7V13.9H51v23.3c0 2.3-.4 4.4-1.1 6.1-.7 1.7-1.7 3.2-3 4.4-1.3 1.2-2.9 2-4.7 2.6-1.8.6-3.9.9-6.1.9-2.2 0-4.3-.3-6.1-.9-1.8-.6-3.4-1.5-4.7-2.6-1.3-1.2-2.3-2.6-3-4.4-.7-1.7-1.1-3.8-1.1-6.1V13.9z"
            ></path>
          </svg>
        </button>
      </div>
      <div class="button-group">
        <button
          class="justify-left"
          :title="labels.justify_left"
          tabindex="-1"
          @click.prevent="change_container_style('text-align', 'left')"
        >
          <svg viewBox="0 0 72 72">
            <path d="M9 14h54v8H9zM9 50h54v8H9zM9 32h36v8H9z"></path>
          </svg></button
        ><button
          class="justify-center"
          :title="labels.justify_center"
          tabindex="-1"
          @click.prevent="change_container_style('text-align', 'center')"
        >
          <svg viewBox="0 0 72 72">
            <path d="M9 14h54v8H9zM9 50h54v8H9zM18 32h36v8H18z"></path>
          </svg></button
        ><button
          class="justify-right"
          :title="labels.justify_right"
          tabindex="-1"
          @click.prevent="change_container_style('text-align', 'right')"
        >
          <svg viewBox="0 0 72 72">
            <path d="M9 14h54v8H9zM9 50h54v8H9zM27 32h36v8H27z"></path>
          </svg>
        </button>
      </div>
      <div class="button-group">
        <button
          :title="labels.remove_formating"
          class="removeformat"
          tabindex="-1"
          @click="remove_formating"
        >
          <svg viewBox="0 0 72 72">
            <path
              d="M58.2 54.6L52 48.5l3.6-3.6 6.1 6.1 6.4-6.4 3.8 3.8-6.4 6.4 6.1 6.1-3.6 3.6-6.1-6.1-6.4 6.4-3.7-3.8 6.4-6.4zM21.7 52.1H50V57H21.7zM18.8 15.2h34.1v6.4H39.5v24.2h-7.4V21.5H18.8v-6.3z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
    <div
      :contenteditable="props.readonly ? 'false' : 'true'"
      v-html="content"
      :class="'editarea ' + (props.readonly ? 'readonly' : 'editable')"
      ref="editor"
      @change="on_div_input"
      @input="on_div_input"
      @paste="on_div_input"
      @click="on_div_click"
      @focus="on_div_click"
      @blur="on_blur"
      v-show="show_div"
    ></div>
    <textarea
      v-show="edit_source"
      ref="source_editor"
      @input.prevent="ta_update"
      @changed.prevent="ta_update"
      @click="is_editing = true"
      @blur="on_blur"
      :value="modelValue"
    ></textarea>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch, readonly } from "vue";
import get_labels from "@/locale/richtexteditor";

const labels = get_labels();

const props = defineProps<{
  modelValue: string;
  readonly?: boolean;
  focus_on_load?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", val: string): void;
}>();

const editor = ref<HTMLDivElement>(),
  source_editor = ref<HTMLTextAreaElement>(),
  content = ref(props.modelValue),
  is_editing = ref(false),
  edit_source = ref(false);

const on_div_input = (): void => {
  let entered = (editor.value as HTMLDivElement).innerHTML;
  is_editing.value = true;
  entered = entered.replace(/<div/g, '<p').replace(/<\/div>/g, '</p>');
  emit("update:modelValue", entered);
};

const on_div_click = (): void => {
  if (props.readonly) return;
  is_editing.value = true;
  setTimeout(() => {
    (editor.value as HTMLDivElement).focus();
  }, 100);
};

const on_blur = ($event: FocusEvent): void => {
  const t1 = $event.relatedTarget as Element;
  if (t1 !== null) {
    const parent = t1.closest(".richtext-editor");
    if (parent != null) return;
  }
  is_editing.value = false;
  content.value = props.modelValue;
};

function ta_update($event: Event) {
  if (props.readonly) return;
  const updated_val = ($event.target as HTMLInputElement).value;
  (editor.value as HTMLDivElement).innerHTML = updated_val;
  emit("update:modelValue", updated_val);
}

const toggle_edit_source = (): void => {
  edit_source.value = !edit_source.value;
  is_editing.value = true;
};

const selected_range = (): Range | null => {
  const sel = document.getSelection();
  if (sel === null) return null;
  is_editing.value = true;
  return sel.getRangeAt(0);
};

const get_selected_container = (): Node | null => {
  const range = selected_range();
  if (range === null) return null;
  return range.commonAncestorContainer;
};

const change_container_style = (property: string, value: string): void => {
  let container = get_selected_container();

  while (
    container?.parentNode instanceof HTMLElement &&
    container.parentNode !== editor.value
  ) {
    container = container.parentNode;
  }
  if (container && container instanceof HTMLElement)
    container.style[property] = value;
  on_div_input();
};

const suround_selection = (node: HTMLElement): void => {
  const range = selected_range();
  if (range === null) return;
  range.surroundContents(node);
  on_div_input();
};

const basic_suround_selection = (tag: string): void => {
  const node = document.createElement(tag);
  suround_selection(node);
};

const remove_tag = (tag: string): string => {
  const itag = tag.toLowerCase();
  if (itag.startsWith("<p") || itag.startsWith("<h") || itag.startsWith("<li"))
    return "<p>";
  if (
    itag.startsWith("</p") ||
    itag.startsWith("</h") ||
    itag.startsWith("</li")
  )
    return "</p>";
  return "";
};

const remove_formating = (): void => {
  let cnt = props.modelValue;
  cnt = cnt.replaceAll(/<[^>]*>/gi, remove_tag);

  (editor.value as HTMLDivElement).innerHTML = cnt;
  emit("update:modelValue", cnt);
};

const show_menu = computed((): boolean => {
  return !props.readonly && is_editing.value;
});

const show_div = computed((): boolean => {
  return !edit_source.value || props.readonly;
});

onMounted(() => {
  if (props.readonly || !props.focus_on_load) return;
  const area = editor.value as HTMLDivElement;
  if (area) {
    area.focus();
  }
  document.addEventListener("click", ($event: Event) => {
    const t1 = $event.target as Element;
    if (t1 !== null) {
      const parent = t1.closest(".richtext-editor");
      if (parent != null) return;
    }
    is_editing.value = false;
    content.value = props.modelValue;
  });
});

watch(
  () => props.modelValue,
  (new_content) => {
    if (edit_source.value || !is_editing.value || props.readonly)
      content.value = new_content;
  },
);
</script>

<style scoped>
.richtext-editor {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.richtext-editor .menu {
  display: flex;
  flex-direction: row;
}

.richtext-editor .menu .button-group {
  display: flex;
  flex-direction: row;
  border: 1px solid grey;
}

.richtext-editor .menu button {
  border: 0;
}

.richtext-editor .menu button svg {
  width: 2rem;
  height: 2rem;
}

.richtext-editor textarea,
.richtext-editor .editarea {
  border: 1px solid var(--medium-light-grey);
  background-color: white;
  color: black;
  flex-grow: 3;
  overflow-y: auto;
  max-height: fit-content;
  padding: 0.5em;
}

.richtext-editor textarea.readonly,
.richtext-editor .editarea.readonly {
  background-color: var(--back-light-grey);
  border: 0;
}

.richtext-editor .editarea {
  line-height: 2em;
}
</style>
