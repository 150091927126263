<template>
  <div class="page-list">
    <h1>{{ default_labels.proposed_issues }}</h1>
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />
    <router-link class="btn btn-default" to="/page/new/edit" style="margin-bottom: 2em; text-decoration: none">
      {{ default_labels.propose_one_issue }}
    </router-link>
    <div class="pages">
      <div v-for="(page, index) in pages" :key="index" class="page-item">
        <router-link :to="{ name: 'PageEditor', params: { slug: page.slug }, query: { locale: page.locale } }">
          {{ page.title }}
        </router-link>
        <div>{{ page.slug }} / {{ page.locale }}</div>
      </div>
    </div>
    <router-link to="/">
      {{ default_labels.return }}
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import Feedbacks from "@/components/Feedbacks.vue";
import { WSClient, ApiMessage } from "@/backend/WSClient";
import { onMounted, ref, watch } from "vue";
import { useLocaleStore } from "@/locale/i18n_store";
import get_default_labels from "@/locale/default";
import { IssueLocalized } from "@/types/common";

interface PageItem {
  slug: string;
  title: string;
  status_id: number;
  locale: string;
}

const pages = ref([] as PageItem[]),
  loading = ref(),
  messages = ref([] as ApiMessage[]),
  client = new WSClient(loading, messages),
  localeStore = useLocaleStore();

const loag_pages = (): void => {
  client.queryWs<{ pages: PageItem[] }>("GET", "/page_list").then((apiResp) => {
    pages.value = apiResp.json.pages as PageItem[];
  });
};

onMounted(() => {
  loag_pages();
});

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const change_status_id = function (issue: IssueLocalized, status_id: number): void {
  issue.status_id = status_id;
};

const default_labels = get_default_labels();
</script>

<style scoped>

.page-item {
  padding: 0.5em 1em;
  border: 1px solid var(--gray-50);
  margin: 1em 0;
}

.page-item a {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  display: block;
}
</style>
