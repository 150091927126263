<template>
  <div>
    <div class="box">
      <font-awesome-icon class="share" icon="share-alt" @click.prevent="share" />
      <h1>{{ page.title }}</h1>
      <div v-html="content_formated"></div>
      <div class="btn-bar">
        <button class="btn" @click.prevent="$router.go(-1)">
          {{ default_labels.return }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nl2p, firstArg } from "@/backend/utils";

import { WSClient, ApiMessage } from "@/backend/WSClient";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore } from "@/backend/session";
import { useRoute } from "vue-router";
import { computed, ref, watch, inject } from "vue";
import get_default_labels from "@/locale/default";
import { Page } from "@/types/common";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const page = ref({
    title: "",
    content: "",
  } as Page),
  loading = ref(false),
  messages = ref([] as ApiMessage[]),
  route = useRoute(),
  localeStore = useLocaleStore(),
  sessionStore = useSessionStore(),
  default_labels = get_default_labels(),
  client = new WSClient(loading, messages),
  mixpanel = inject("mixpanel") as any;

const load_page = (slug: string | undefined): Promise<Page | null> => {
  return client
    .queryWs<{ page: Page }>("GET", "/page/" + slug + "?lang=" + (url_lang.value ?? localeStore.locale))
    .then((resp) => {
      page.value = resp.json.page;
      document.querySelectorAll('meta[name^="og:"').forEach((n) => {
        n.remove();
      });
      mixpanel.track("show_page", { slug: slug });
      return page.value;
    });
};

const content_formated = computed((): string => {
  return nl2p(page.value.content);
});

const share = (): void => {
  const page_obj = page.value;
  if (page_obj === null || !page_obj.slug) return;

  if (navigator.share) {
    navigator
      .share({
        title: page_obj.title,
        text: page_obj.content,
        url: window.location.href + window.location.search,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    toClipboard(window.location.href + window.location.search).then(() => {
      toast(default_labels.value.copied, {
        autoClose: 1000,
      });
    });
  }
};

const url_lang = computed((): string | undefined => {
  return firstArg(route.query.lang);
});

watch(
  () => route.params.slug,
  (slug) => {
    load_page(firstArg(slug));
  },
  { immediate: true }
);

sessionStore.require_no_session();
</script>

<style scoped>
.issue {
  margin-top: 3rem;
  position: relative;
}

.share {
  position: absolute;
  right: 1.5em;
  font-size: 1.6em;
  cursor: pointer;
}

.issue .loader {
  position: absolute;
  right: 4rem;
  top: 2rem;
  width: 1.5rem;
}

.issue .body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}

.result {
  font-size: 1.3em;
  font-weight: bold;
}

.right {
  color: var(--green-medium);
}

.wrong {
  color: var(--fuschia-79);
}

.certificate-menu {
  display: flex;
  align-items: center;
  gap: 1em;
}

.certificate-menu svg.btn {
  font-size: 1.1em;
  padding: 0.7em 1em;
}
</style>
