import { outcome_label } from '../backend/outcome';
export interface User {
  user_id?: number;
  name?: string;
  locale: string;
  email?: string;
  connection_token?: string;
  created?: string;
  last_connected?: string;
}

export interface Response {
  connected: boolean;
  infos?: Feedback[];
  warnings?: Feedback[];
  errors?: Feedback[];
  fatals?: Feedback[];
  status: string;
}

export interface Feedback {
  level?: string;
  reason: string;
  bundle: string;
  values: null[];
  message: string;
}

export interface SessionResponse {
  connected: boolean;
  jwt?: string;
}

export interface IssueList {
  issues: Issue[];
}

export interface IssueLocalized {
  issue_id?: number;
  creator_id?: number;
  delay: number;
  created?: string;
  starts_on: string;
  ends_on: string;
  ended_on: string;
  close_note?: string; 
  status_id?: number;
  locale: string;
  slug?: string;
  title: string;
  tags: string;
  description: string;
  outcomes: Outcome[];
  forecast_outcome?: number;
  forecast_date?: string;
  final_outcome?: number | null;
  picture?: string;
}

export interface Issue {
  issue_id?: number;
  creator_id?: number;
  status_id: number;
  delay: number;
  created?: string;
  starts_on?: string;
  ends_on?: string;
  ended_on?: string;
  final_outcome?: number;
  outcomes: Outcome[];
}

export interface IssueLocale {
  issue_id?: number;
  locale: string,
  slug?: string,
  title: string,
  tags?: string,
  description: string,
  outcomes_locale?: IssueLocaleLocale
}

export interface Outcome {
  condition_type: ConditionType;
  label?: string;
  ends_on?: string;
  offset?: number;
}

export interface IssueLocaleLocale {
  outcome_labels?: string[];
}

export interface OutcomeStat {
  outcome: number;
  count: number;
}

export enum ConditionType {
  Before = "Before",
  Default = "Default",
  StringValue = "StringValue",
  Other = "Other",
  Never = "Never",
  After = "After",
  Yes = "Yes",
  No = "No",
}

export interface Page {
  locale: string;
  slug: string;
  title: string;
  content: string;
  picture?: string;
}
