import type { Ref } from "vue";
import { useSessionStore } from "@/backend/session";

export interface CallForAction {
  action: string;
  text: string;
}
export interface ApiMessage {
  level: string;
  text: string;
  style?: string;
  title?: string;
  call_for_action?: CallForAction;
}

export interface ApiResponse {
  messages?: ApiMessage[];
  user_id?: number;
  user_name?: string;
  user_hash?: string;
  connected?: boolean;
  jwt?: string;
  jwt_expires?: number;
  status?: string;
}

export interface ApiMessageNs {
  reason?: string;
  bundle?: string;
  values?: string[];
  message?: string;
}

export interface RestResponse {
  status: number;
  json?: ApiResponse;
  textData?: string;
}

export type Methods = "GET" | "POST" | "PUT" | "DELETE";

/**
 * Web-service client
 * @param {*} parent Parent object that will holds results, messages and loading indicator.
 */
export class WSClient {
  static readonly rootApi = (import.meta.env.VITE_SERVER_BASE || "") + (import.meta.env.VITE_API || "/api");
  loading?: Ref<boolean>;
  messages?: Ref<ApiMessage[]>;
  authStore = useSessionStore();
  last_status = 0;

  constructor(loading?: Ref<boolean>, messages?: Ref<ApiMessage[]>) {
    this.loading = loading;
    this.messages = messages;
  }

  /**
   * Low level query, handle loading and redirection if not auth.
   *
   * @param method
   * @param url
   * @param data
   * @param headers
   * @returns
   */
  async query(
    method: Methods,
    url: string,
    data: BodyInit | null | undefined,
    headers: HeadersInit | undefined
  ): Promise<Response> {
    this.onLoading(true);

    const httpResponse = await fetch(url, {
      mode: "cors",
      credentials: "include",
      method: method,
      headers: headers,
      body: data,
    });
    this.onLoading(false);

    if (httpResponse.status == 401) {
      this.authStore.action = "login";
    }

    return httpResponse;
  }

  /**
   * Query adapted to generic JSON WS.
   * Handle messages and non JSON responses.
   *
   * @param method
   * @param url
   * @param data
   * @returns
   */
  async queryJson(method: Methods, url: string, data: unknown | undefined): Promise<any> {
    const headers = new Headers({});
    if (data) headers.append("Content-Type", "application/json");
    if (this.authStore.token) headers.append("authorization", this.authStore.token);

    const httpResponse = await this.query(method, url, data ? JSON.stringify(data) : null, headers);

    this.last_status = httpResponse.status;

    const contentType = httpResponse.headers.get("Content-type");
    let the_text;
    if (contentType && contentType.startsWith("application/json")) {
      try {
        const json = await httpResponse.json(),
          status = httpResponse.status;

        if (!json) {
          if (httpResponse.ok) {
            return Promise.resolve(status);
          }
          return Promise.reject(status);
        }

        this.setMessages(json?.messages);
        const resp = { status, json };
        return httpResponse.ok ? Promise.resolve(resp) : Promise.reject(resp);
      } catch (err) {
        return this.handleError(httpResponse.status, err);
      }
    }

    return this.handleText(httpResponse.ok, httpResponse.status, the_text || (await this.getText(httpResponse)));
  }

  /**
   * Post media
   *
   * @param url
   * @param file
   * @returns the HTTP code
   */
  async postMedia(
    service: string,
    urlParams: Record<string, string | number | boolean | number[] | string[]> | undefined | null,
    field: string,
    file: File | Blob
  ): Promise<number> {
    var data = new FormData();
    data.append(field, file);

    const headers = new Headers({});
    if (this.authStore.token) headers.append("authorization", this.authStore.token);

    const httpResponse = await this.query("POST", this.getWsUrl(service, urlParams), data, headers);

    return httpResponse.status;
  }

  handleText(isOk: boolean, status: number, text: string | null): Promise<any> {
    const resp = { status: status, text: text };
    if (isOk) {
      return Promise.resolve(resp);
    }
    this.setMessages([{ level: "error", text: "Error " + text }]);
    return Promise.reject(resp);
  }

  handleError(status: number, error: unknown): Promise<ApiResponse> {
    this.onLoading(false);

    if (Array.isArray(error)) {
      this.setMessages(error);
    } else {
      const message = error instanceof Error ? error.message : String(error);
      this.setMessages([{ level: "error", text: "Error " + message }]);
    }

    return new Promise(function (resolve, reject) {
      reject({ status, error });
    });
  }

  setMessages(input: ApiMessage[] | undefined): void {
    if (this.messages) {
      this.messages.value = [];
      const m_arr = input;
      if (m_arr) {
        this.messages.value.push(...m_arr);
      }
    }
  }

  onLoading(isLoading: boolean): void {
    if (this.loading) {
      this.loading.value = isLoading;
    }
  }

  getWsUrl(
    service: string,
    urlParams: Record<string, string | number | boolean | number[] | string[]> | undefined | null
  ): string {
    return WSClient.rootApi + service + this.toQueryString(urlParams);
  }

  toQueryString(arr: Record<string, string | number | boolean | number[] | string[]> | undefined | null): string {
    if (!arr) return "";
    const params = [] as string[];
    Object.keys(arr)
      .filter((key) => typeof arr[key] != "undefined")
      ?.forEach((key) => {
        const k = `${key}=`,
          v = arr[key];
        if (v === null) return;
        if (Array.isArray(v)) {
          v?.forEach((v2) => {
            params.push(k + encodeURIComponent(v2.toString()));
          });
        } else {
          params.push(k + encodeURIComponent(v.toString()));
        }
      });
    return "?" + params.join("&");
  }

  queryWs<T>(
    method: Methods,
    path: string,
    urlParams?: Record<string, string | number | boolean | number[] | string[]> | null,
    body?: unknown
  ): Promise<{ status: number; json: T }> {
    return this.queryJson(method, this.getWsUrl(path, urlParams), body);
  }

  setMess(input: any, key: string, level: string): ApiMessage[] {
    if (!input[key]) return [];
    const tr = input[key].map((e: ApiMessageNs) => {
      return { level: level, text: e.message } as ApiMessage;
    });
    delete input[key];
    return tr;
  }

  getText = async (httpResponse: Response): Promise<string | null> => {
    let resp = await httpResponse.text();
    if (resp) return resp;
    return null;
  };

  nsAdapter(input: any, status: number): ApiResponse {
    const mess = ([] as ApiMessage[]).concat(
      this.setMess(input, "errors", "error"),
      this.setMess(input, "warnings", "warning"),
      this.setMess(input, "infos", "info")
    );

    input.messages = input.messages ? mess.concat(input.messages) : mess;

    if (status == 503) {
      if (!input.messages) input.messages = [] as ApiMessage[];
      input.messages.push({ level: "error", text: "" } as ApiMessage);
    }
    if (input["user-name"]) {
      input.user_name = input["user-name"];
      delete input["user-name"];
    }
    if (input["user-id"]) {
      input.user_name = input["user-id"];
      delete input["user-id"];
    }
    if (input["user-hash"]) {
      input.user_hash = input["user-hash"];
      delete input["user-hash"];
    }

    return input as ApiResponse;
  }
}
