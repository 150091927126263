<template>
  <select :value="props.modelValue" @change="change">
    <option value="Yes">{{ default_labels.yes }}</option>
    <option value="No">{{ default_labels.no }}</option>
    <option value="Before">{{ default_labels.before }}</option>
    <option value="StringValue">{{ default_labels.stringvalue }}</option>
    <option value="Other">{{ default_labels.other }}</option>
    <option value="Never">{{ default_labels.never }}</option>
  </select>
</template>

<script lang="ts" setup>
import get_default_labels from "@/locale/default";
import { ConditionType } from "@/types/common";


const props = defineProps<{
  modelValue: ConditionType;
}>();

const default_labels = get_default_labels();

const emit = defineEmits<{
  (e: "change", value: ConditionType): void;
}>();

function change($event: Event): void {
  const val = ($event.target as HTMLSelectElement).value as ConditionType;
  emit("change", val);
}
</script>
