<template>
  <div class="user_recovery">
    <Feedbacks v-if="messages" :messages="messages" @close="removeMessage($event)" />
    <div style="text-align: center; margin-bottom: 4rem">
      <router-link to="/">
        {{ default_labels.return }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Feedbacks from "@/components/Feedbacks.vue";
import { WSClient, ApiResponse, ApiMessage } from "@/backend/WSClient";

export default defineComponent({
  components: {
    Feedbacks,
  },
});
</script>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useSessionStore } from "@/backend/session";
import get_default_labels from "@/locale/default";
import { useRoute } from "vue-router";
import router from "@/router";
import { firstArg, token } from "@/backend/utils";

const sessionStore = useSessionStore(),
  default_labels = get_default_labels(),
  messages = ref([] as ApiMessage[]),
  loading = ref(false),
  route = useRoute(),
  client = new WSClient(loading, messages);

interface UserResponse extends ApiResponse {
  user_id?: number;
  name: string;
  locale: string;
  email: string;
  connection_token?: string;
  created?: string;
  last_connected?: string;
}

function get_logged(): void {
  client.queryWs<UserResponse>("GET", "/user/logged").then((apiResp) => {
    sessionStore.logged_user = apiResp.json;
  });
}

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const send_recall_request = async () => {
  const email = firstArg(route.query.email);
  const hash = firstArg(route.query.hash);
  if (!email || !hash) {
    messages.value = [{level: "error", text: default_labels.value.bad_recovery_link}];
    return;
  }
  const rep = await client.queryWs<{token: string}>("POST", "/user/recovery", null, {
        email: email,
        hash: hash,
      });
    if(rep.status === 200){
      sessionStore.token = rep.json.token;
        get_logged();
        sessionStore.action = null;
        router.push({ name: "Home" });
    } else {
      messages.value = [{level: "error", text: default_labels.value.error}];
    }
}

onMounted(() => {
  send_recall_request();
});
</script>
