export default `
<h1>Help</h1>
<p>We all have ideas about current events and happenings around us. We can even make predictions about our favourite
our favorite subjects. What a satisfaction when our predictions turn out to be right, despite the contrary opinions.</p>

<p>Kassandr is a site that allows you to predict and check your forecasts. Each news item is the subject of a question
and several possible questions. Unlike a survey, the goal is not to answer what you would like, but what you think will happen.
you think will happen. Once the event is over, you can check your prediction and maybe even claim to have predicted the future.
to have predicted the future!</p>

<p>For any help or suggestions, please write to us at the following address <a href="mailto:info@kassandr.app">info@kassandr.app</a></p>
`;
