import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/issue/Home.vue";
import Issue from "../views/issue/Issue.vue";
import Page from "../views/page/Page.vue";
import PageList from "../views/page/PageList.vue";
import PageEditor from "../views/page/PageEditor.vue";
import IssueEditor from "../views/issue/IssueEditor.vue";
import IssueStatus from "../views/issue/IssueStatus.vue";
import MyForecasts from "../views/issue/MyForecasts.vue";
import MyProposedIssues from "../views/issue/MyProposedIssues.vue";
import UserMenu from "../views/user/UserMenu.vue";
import Profile from "../views/user/Profile.vue";
import UserRecovery from "../views/user/UserRecovery.vue";
import Help from "../views/misc/Help.vue";
import Translations from "../views/admin/Translations.vue";
import TranslateAssistant from "../views/admin/TranslateAssistant.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/issue/:slug",
    name: "Issue",
    component: Issue,
  },
  {
    path: "/q/:slug",
    name: "Issue",
    component: Issue,
  },
  {
    path: "/issue/:slug/status",
    name: "IssueStatus",
    component: IssueStatus,
  },
  {
    path: "/my_forecasts",
    name: "MyForecasts",
    component: MyForecasts,
  },
  {
    path: "/proposed_issue/list",
    name: "MyProposedIssues",
    component: MyProposedIssues,
  },
  {
    path: "/proposed_issue/:id/edit",
    name: "IssueEditor",
    component: IssueEditor,
  },
  {
    path: "/p/:slug",
    name: "Page",
    component: Page,
  },
  {
    path: "/page/list",
    name: "PageList",
    component: PageList,
  },
  {
    path: "/page/:slug/edit",
    name: "PageEditor",
    component: PageEditor,
  },
  {
    path: "/user_menu",
    name: "UserMenu",
    component: UserMenu,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/user_recovery",
    name: "UserRecovery",
    component: UserRecovery,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/misc/About.vue"),
  },
  {
    path: "/translations",
    name: "Translations",
    component: Translations,
  },
  {
    path: "/translate_assistant",
    name: "TranslateAssistant",
    component: TranslateAssistant,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});


export default router;
