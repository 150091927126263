import { IssueLocalized } from '../../types/common';
<template>
  <div :class="is_closed ? 'outcomes closed' : 'outcomes'">
    <ButtonWithIndicator
      v-for="(outcome, index) in issue.outcomes"
      :key="index"
      class="btn btn-default outcome"
      :style="button_style(index)"
      :class="outcome_class(index)"
      :disabled="is_outcome_overdue(issue, outcome)"
      :show_indicator="outcome_loading_idx == index && outcome_loading"
      @click.prevent="choose_outcome(index)"
    >
      <div v-html="outcome_label(outcome, !is_closed && !is_outcome_overdue(issue, outcome), default_labels.before , datetime_format, date_format, relative_datetime_format)"></div>
    </ButtonWithIndicator>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { IssueLocalized } from "@/types/common";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";
import { outcome_label, button_style, is_outcome_overdue } from "@/backend/outcome";
import { useLocaleStore } from "@/locale/i18n_store";
import { outcome_type } from "@/backend/utils";
import get_default_labels from "@/locale/default";


const props = defineProps<{
  issue: IssueLocalized;
}>();

const emit = defineEmits<{
  (e: "choose_outcome", val: number): void;
}>();

const localeStore = useLocaleStore(),
  datetime_format = localeStore.datetime_format,
  date_format = localeStore.date_format,
  relative_datetime_format = localeStore.relative_datetime_format,
  default_labels = get_default_labels();

const outcome_loading_idx = ref(-1),
  outcome_loading = ref(false);

// Defined sucess
const outcome_class = function (index: number): string {
  return outcome_type(props.issue, index);
};

const choose_outcome = async function (index: number): Promise<void> {
  outcome_loading_idx.value = index;
  outcome_loading.value = true;
  await emit("choose_outcome", index);
  outcome_loading.value = false;
  outcome_loading_idx.value = -1;
};

const is_closed = computed((): boolean => {
  const fo = props.issue?.final_outcome;
  return fo !== null && fo !== undefined;
});
</script>

<style scoped>
.outcomes {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 1.3em;
}

.outcome > * {
  text-shadow: none;
}

.outcome.overdue {
  color: rgb(46, 46, 46);
  font-style: italic;
  cursor: not-allowed;
}

.closed .outcome {
  cursor: not-allowed;
  font-style: normal;
}

.issue .outcome .sub {
  font-size: 0.6em;
  font-weight: normal;
}

.outcome.chosen {
  border: 3px solid white;
}

.outcome.correction {
  border: 3px solid var(--blue-dark);
}

.outcome.failure {
  border: 3px solid var(--fuschia-79);
  text-decoration: line-through;
}

.outcome.closed {
  text-decoration: line-through;
}

.outcome.success {
  border: 3px solid var(--green-medium);
}

button:disabled {
  text-decoration: line-through;
  color: var(--gray-3);
}
</style>
