import { DefaultLabels } from "@/locale/default";

export default {
  "already_registered": "I have already an account",
  "app_subtitle": "This will happen",
  "app_title": "Kassandr{0}a",
  "ask_reminder": "Account recovery",
  "bad_recovery_link": "Bad recovery link",
  "before": "Before",
  "certificate_body": "To the question &quot;{0}&quot;, {1} successfully predicted the answer &quot;{2}&quot; on {3}, i.e. {4} before it was verified.",
  "certificate_title": "Certificate of prediction.",
  "click_to_insert_content": "Click here to insert your content",
  "close": "Close",
  "close_note": "Closing note",
  "closed": "Closed",
  "copied": "Copied",
  "create": "Create",
  "days": "days",
  "default": "Default",
  "delay": "Forecast delay",
  "description": "Description",
  "description_explain": "Details to understand the issues, the sources of information used to decide the question and any other important points.",
  "disconnect": "Disconnect",
  "display_name": "Display name",
  "download": "Download",
  "edit_profile": "Please update your profile information.",
  "email": "Email",
  "email_for_answer": "Your email for answer",
  "email_sending_error": "Email sending error",
  "email_taken_error": "Email allready taken. Use account recovery or choose another email address",
  "ended_on": "Ended on",
  "error": "An error occured",
  "final_outcome": "Final outcome",
  "first": "First",
  "forecast_with_advance": "Advance of forecast: {0}.",
  "forecasted": "Forecasted",
  "help": "help",
  "hours": "hours",
  "iknow": "I know!",
  "inverted_start_end": "The end date must be after the start date",
  "issue_confirmation": "Your forecast has been recorded!",
  "issue_lost": "What a pitty! You didn't manage to forecast this event. Perhaps another time...",
  "issue_lost_short": "Failure",
  "issue_won": "Well done! You've managed to forecast this event!",
  "issue_won_short": "Success",
  "language": "Language",
  "last": "Last",
  "locale": "en",
  "login": "Login",
  "login_link_explain": "Please enter your email address. You will receive an email with a link to log back into your user profile",
  "logout": "Logout",
  "minimum_outcomes": "An issue should have a minimum of 2 outcomes",
  "modify": "Modify",
  "months": "months",
  "more": "More",
  "my_forecasts": "My forecasts",
  "never": "After or never",
  "new_locale": "New locale version",
  "next": "Next",
  "no": "No",
  "no_email": "We don't have your email to send your forecasts results. Please fill it {here}.",
  "offset": "offset",
  "one_result": "result",
  "other": "Other",
  "outcomes": "Outcomes",
  "outcomes_explain": "Different possible outcomes: yes/no, options, deadlines",
  "paste_here": "Paste here",
  "picture": "Picture",
  "picture_explain": "An image to illustrate the question that will be displayed during sharing.",
  "please_register": "You are not connected. Please enter your profile information.",
  "previous": "Previous",
  "primary_language": "Primary language",
  "propose_one_issue": "Propose on issue",
  "proposed_by": "Proposed by {0}",
  "proposed_issues": "Proposed issues",
  "publish": "Publish",
  "publish_expl": "Do you want to make this issue public online now ?",
  "question": "Question",
  "question_example": "What will the weather be like at Christmas?",
  "question_explain": "A short question that may have a determined and verifiable answer in the future.",
  "recall_sent": "A recall message has been sent.",
  "register": "Register",
  "resolve": "Resolve",
  "resolve_explain": "You now need to fill in the outcome that has been achieved, according to the sources of verification defined at the outset.",
  "results": "results",
  "return": "Return",
  "save": "Save",
  "secret_hash": "Secret hash key",
  "send": "Send",
  "server_unavailable": "Server unreachable",
  "should_be_auth": "You should be authenticated for this. Please re-login or register.",
  "slug": "URL ID",
  "slug_explain": "Unique address used for sharing on networks and referencing.",
  "starts_on": "Starts on",
  "status": "Status",
  "stringvalue": "Value",
  "success": "Operation succesfull",
  "support": "Support",
  "support_explain": "Please send your request, we are here to support you.",
  "support_placeholder": "Please describe the circumstances of your issue and the browser used.",
  "support_tooltip": "Ask for help",
  "tags": "Tags",
  "tags_explain": "Used to sort and find questions.",
  "thanks": "Thank you !",
  "timeframe": "Timeframe",
  "timeframe_explain": "All date and times are shown with {0} timezone.",
  "to_end_on": "To end on",
  "user_account_not_found": "User account not found",
  "user_edit": "User edit",
  "user_profile": "User profile",
  "weeks": "weeks",
  "welcome": "Welcome {0}",
  "welcome_back": "Welcome back {0}",
  "withdraw": "Withdraw",
  "years": "years",
  "yes": "Yes",
  "you_were_right": "You where right!",
  "you_were_wrong": "You where wrong!",
  "your_forecast": "Your forecast",
  "your_phone": "Your phone number",
  "edit_page": "Page edition",
  "content": "Content",
  "title": "Title",
  "page_title_explain": "The title of the page shown sharing",
  "page_content_explain": "HTML content shown"
} as DefaultLabels;
