import get_default_labels from "@/locale/default";
import { Outcome, ConditionType } from "@/types/common";
import { hours_to_duration } from "@/backend/utils";
import { CSSProperties } from "vue";
import { IssueLocalized } from '../types/common';

const default_labels = get_default_labels();

export function outcome_label_common(outcome: Outcome): string {
  if (outcome.condition_type === ConditionType.Never) return default_labels.value.never;
  if (outcome.condition_type === ConditionType.Other) return default_labels.value.other;
  if (outcome.condition_type === ConditionType.Yes) return default_labels.value.yes;
  if (outcome.condition_type === ConditionType.No) return default_labels.value.no;
  if (outcome.condition_type === ConditionType.Default) return "None of theses";
  return "Error option";
}

const date_diff = function (dateObj: Date): number {
  return Math.floor((dateObj.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
};

export function outcome_short_label(outcome: Outcome, relative_datetime_format: Intl.RelativeTimeFormat): string {
  if (outcome.label) return outcome.label;
  if (outcome.condition_type === ConditionType.Before && outcome.ends_on) {
    if (!outcome.ends_on) return "";
    const dateObj = new Date(outcome.ends_on),
      diff = date_diff(dateObj);

    return relative_datetime_format.format(diff, "day");
  }
  return outcome_label_common(outcome);
}

export function outcome_label(outcome: Outcome, is_choosable: boolean, before_loc: string, datetime_format: Intl.DateTimeFormat, date_format: Intl.DateTimeFormat,  relative_datetime_format: Intl.RelativeTimeFormat): string {
  if (outcome.condition_type === ConditionType.StringValue) {
    return '<div class="main">' + outcome.label + "</div>";
  }
  if (outcome.condition_type === ConditionType.Before && outcome.ends_on) {
    const dateObj = new Date(outcome.ends_on),
      date_str = outcome.ends_on.includes("00:00:00") ? date_format.format(dateObj) : datetime_format.format(dateObj),
      date_str_div = date_str ? '<div class="abs">' + before_loc + " " + date_str + "</div>" : "";
    
      if(!is_choosable) return date_str_div; 
    
    const diff = date_diff(dateObj),
      duration = hours_to_duration(diff * 24),
      diff_str = relative_datetime_format.format(duration.value, duration.unit);
    
    return  date_str_div + '<div class="rel">(' + diff_str  + ")</div>";
  }

  return outcome_label_common(outcome);
}

//const defined_colors = ["#862e9c", "#cc5de8", "#e599f7", "#f3d9fa", "#f8f0fc"];
export const defined_colors = [
    "#eb6f6f",
    "#ebba6f",
    "#d3eb6f",
    "#88eb6f",
    "#6feba1",
    "#6febeb",
    "#6fa1eb",
    "#886feb",
    "#d36feb",
    "#eb6fba",
    "#bc418c",
  ];
  
  export function button_style(index: number): CSSProperties {
    return {"background-color": defined_colors[index]};
  };

  export function is_outcome_overdue(issue: IssueLocalized, outcome: Outcome): boolean {
    if (outcome.condition_type !== ConditionType.Before || outcome.ends_on === undefined || outcome.ends_on === null) return false;
    return Date.parse(outcome.ends_on) - issue.delay * 1000 < new Date().getTime();
  };
