<template>
  <div class="help" v-html="help"></div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import default_help from "@/locale/lang/help_en";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore } from "@/backend/session";

const help = ref(default_help as string);
const localeStore = useLocaleStore();
import(
  /* webpackChunkName: "loc" */ `@/locale/lang/help_${localeStore.locale}`
).then((text) => {
  help.value = text.default;
});
const sessionStore = useSessionStore();
sessionStore.require_no_session();
</script>

<style scoped>
:deep(a) {
  color: var(--fuschia-57);
}
</style>
