<template>
  <div class="backcloak">
    <div class="popup" :style="window_style">
      <div class="head" v-if="title">
        <template v-if="title">{{ title }}</template>
        <slot v-else name="header"></slot>
        <font-awesome-icon icon="times" @click.prevent="close" />
      </div>
      <div class="body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from 'vue';

const props = defineProps<{
  title: String;
  window_style?: CSSProperties;
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const close = () => {
  emit("close");
};
</script>

<style scoped>
.backcloak {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
  text-align: center;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}

.popup {
  display: inline-block;
  position: relative;
  text-align: left;
  box-shadow: 1px 1px 12px black;
  border-radius: 5px;
}

.popup .head {
  padding: 1rem 4rem;
  color: var(--gray-50);
  background-color: var(--gray-15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  border-radius: 5px 5px 0 0;
}

.popup .head svg {
  cursor: pointer;
  color: var(--medium-light-grey);
}

.popup .body {
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  background-color: var(--gray-77);
  color: var(--gray-3);
  border-radius: 0 0 5px 5px;
}
</style>
