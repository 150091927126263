<template>
  <div>
    <div v-for="(message, index) in props.messages" :key="message.text" :class="'alert ' + message.level">
      <span v-html="message.text"></span>
      <font-awesome-icon class="close" icon="times" @click.prevent="close(index)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ApiMessage } from "@/backend/WSClient";

const props = defineProps<{
  messages: ApiMessage[];
}>();

const emit = defineEmits<{
  (e: "close", value: number): void;
}>();

const close = (index: number): void => {
  emit("close", index);
};
</script>

<style>
.alert {
  margin-bottom: 0.5em;
  padding: 0.4em 1.5em 0.4em 0.5em;
  position: relative;
  border-radius: 3px;
}

.alert .close {
  position: absolute;
  right: 0.4em;
  top: 0.4em;
  cursor: pointer;
  font-weight: bold;
  opacity: 0.5;
  font-size: 1.1em;
}

.alert a.close:hover {
  opacity: 1;
}

.alert.fatal {
  border: 1px solid var(--orange-dark);
  background-color: var(--orange-light);
  color: var(--orange-dark);
  font-weight: bold;
}

.alert.error {
  color: var(--fuschia-79);
  border: 1px solid var(--fuschia-57);
  background-color: var(--fuschia-20);
}

.alert.warning {
  border: 1px solid var(--orange-dark);
  background-color: var(--orange-light);
  color: var(--orange-dark);
}

.alert.warning a {
  color: var(--orange-darker);
}

.alert.success {
  border: 1px solid var(--green-dark);
  background-color: var(--green-light);
  color: var(--green-dark);
}

.alert.info {
  border: 1px solid var(--violet-dark);
  background-color: var(--violet-light);
  color: var(--violet-dark);
}
</style>
