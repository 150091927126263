import { RichTextEditorLabel } from "@/locale/richtexteditor";

export default {
  "del": "Barré",
  "edit_source": "Editer la source HTML",
  "emphasize": "En italique",
  "justify_center": "Aligné au centre",
  "justify_left": "Aligné à gauche",
  "justify_right": "Aligné à droite",
  "locale": "fr",
  "remove_formating": "Supprimer le formatage",
  "strong": "En gras",
  "underline": "Souligné"
} as RichTextEditorLabel;
