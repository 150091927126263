<template>
  <div class="user-menu">
    <h1>{{ default_labels.user_profile }}</h1>
    <router-link class="btn btn-default" to="/my_forecasts">
      {{ default_labels.my_forecasts }}
    </router-link>
    <router-link class="btn btn-default" to="/profile/">
      {{ default_labels.user_edit }}
    </router-link>
    <router-link class="btn btn-default" to="/proposed_issue/list">
      {{ default_labels.proposed_issues }}
    </router-link>
    <router-link to="/">
      {{ default_labels.return }}
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import { useSessionStore } from "@/backend/session";
import get_default_labels from "@/locale/default";

const default_labels = get_default_labels();
const sessionStore = useSessionStore();
sessionStore.require_session();
</script>

<style>
.user-menu {
  display: flex;
  flex-direction: column;
  max-width: 30em;
  margin: 3em auto;
  gap: 1em;
}

.user-menu h1 {
  margin-bottom: 1.5em;
}

.user-menu a {
  margin-bottom: 2em;
  text-decoration: none;
}
</style>
