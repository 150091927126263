import { computed, ComputedRef } from "vue";
import { useLocaleStore } from "@/locale/i18n_store";
import default_loc from "./lang/default_en";

export interface Translations {
  direction?: string;
}

export interface DefaultLabels extends Translations {
  already_registered: string;
  app_subtitle: string;
  app_title: string;
  ask_reminder: string;
  bad_recovery_link: string;
  before: string;
  certificate_title: string;
  certificate_body: string;
  click_to_insert_content: string;
  close: string;
  close_note: string;
  closed: string;
  copied: string;
  create: string;
  days: string;
  default: string;
  timeframe: string;
  delay: string;
  description: string;
  description_explain: string;
  disconnect: string;
  display_name: string;
  download: string;
  edit_profile: string;
  email: string;
  email_for_answer: string;
  email_sending_error: string;
  email_taken_error: string;
  ended_on: string;
  error: string;
  final_outcome: string;
  first: string;
  forecast_with_advance: string;
  forecasted: string;
  help: string;
  hours: string;
  iknow: string;
  inverted_start_end: string;
  issue_confirmation: string;
  issue_lost: string;
  issue_won: string;
  language: string;
  last: string;
  locale: string;
  login: string;
  login_link_explain: string;
  logout: string;
  minimum_outcomes: string;
  modify: string;
  months: string;
  more: string;
  my_forecasts: string;
  never: string;
  new_locale: string;
  next: string;
  no: string;
  no_email: string;
  one_result: string;
  other: string;
  outcomes: string;
  outcomes_explain: string;
  paste_here: string;
  picture: string,
  picture_explain: string;
  please_register: string;
  previous: string;
  propose_one_issue: string;
  proposed_by: string;
  proposed_issues: string;
  publish: string;
  publish_expl: string;
  question: string;
  question_explain: string;
  question_example: string;
  recall_sent: string;
  register: string;
  resolve: string;
  resolve_explain: string;
  results: string;
  return: string;
  save: string;
  secret_hash: string;
  send: string;
  server_unavailable: string;
  should_be_auth: string;
  slug: string;
  slug_explain: string;
  starts_on: string;
  status: string;
  stringvalue: string;
  success: string;
  support: string;
  support_explain: string;
  support_placeholder: string;
  support_tooltip: string;
  tags: string;
  tags_explain: string;
  thanks: string;
  timeframe_explain: string;
  to_end_on: string;
  user_account_not_found: string;
  user_edit: string;
  user_profile: string;
  weeks: string;
  welcome: string;
  welcome_back: string;
  withdraw: string;
  years: string;
  yes: string;
  your_phone: string;
  your_forecast: string;
  you_were_right: string;
  you_were_wrong: string;
  offset: string;
  edit_page: string;
  content: string;
  title: string;
  page_title_explain: string;
  page_content_explain: string;
}

export type SupportedLanguages = "ar" | "az" | "de" | "en" | "es" | "fa" | "fr" | "he" | "hy" | "id" | "it" | "ja" | "nl" | "pl" | "pt" | "ru" | "tr" | "uk" | "zh-CN" | "zh-TW";
export type LocKey = "default" | "countries" | "richtexteditor" | "session" | "smtp";

export const supported_language_options = [
  {code:"ar", label: "عربي"},
  {code:"az", label: "azəri"},
  {code:"de", label: "Deutsche"},
  {code:"en", label: "English"},
  {code:"es", label: "Español"},
  {code:"fa", label: "فارسی"},
  {code:"fr", label: "Français"},
  {code:"he", label: "עברית"},
  {code:"hy", label: "հայերեն"},
  {code:"id", label: "Bahasa Indonesia"},
  {code:"it", label: "Italiano"},
  {code:"ja", label: "日本語"},
  {code:"nl", label: "Dutch"},
  {code:"pl", label: "Polski"},
  {code:"pt", label: "Português"},
  {code:"ru", label: "Русский"},
  {code:"tr", label: "Türkçe"},
  {code:"uk", label: "Український"},
  {code:"zh-CN", label: "中文 (中国大陆)"},
  {code:"zh-TW", label: "中文 (台灣)"}
];

export function loadLocale(
  key: LocKey,
  defaultValues: Translations,
): Translations {
  const localeStore = useLocaleStore();
  const filename = key + "_" + localeStore.locale,
    localized = localeStore.i18n[filename] as Translations;
  if (!localized) {
    localeStore.load_locale(key);
  }
  return localized || defaultValues;
}

export default function get(): ComputedRef<DefaultLabels> {
  const fn = (): DefaultLabels => {
    return loadLocale("default", default_loc) as DefaultLabels;
  };
  return computed(fn);
}
