<template>
  <select :value="props.lang" @change="change" style="min-width: 10em">
    <option v-for="alang in supported_language_options" :value="alang.code">{{ alang.label }}</option>
  </select>
</template>

<script lang="ts" setup>
import {
  supported_language_options,
  SupportedLanguages,
} from "@/locale/default";

const props = defineProps<{
  lang?: SupportedLanguages;
}>();

const emit = defineEmits<{
  (e: "change", value: SupportedLanguages): void;
}>();

function change($event: Event): void {
  const val = ($event.target as HTMLSelectElement).value as SupportedLanguages;
  emit("change", val);
}
</script>
