<template>
  <div :class="theClass">&nbsp;</div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  show_indicator: boolean;
}>();

const theClass = computed(() => {
  return props.show_indicator ? "indicator on" : "indicator";
});
</script>

<style>
.indicator {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.indicator.on {
  position: absolute;
  display: inline-block;
  right: 1em;
  top: 1.25em;
  height: 1.4em;
  width: 1.4em;
  border: 3px solid var(--very-light-grey);
  border-top: 3px solid var(--main-color-dark-red);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
</style>
