<template>
  <div class="home">
    <h1>{{ default_labels.proposed_issues }}</h1>
    <SearchBox style="margin: 3rem 0" @change="search($event)" />
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />
    <router-link class="btn btn-default" to="/proposed_issue/new/edit" style="margin-bottom: 2em; text-decoration: none">
      {{ default_labels.propose_one_issue }}
    </router-link>
    <div style="margin-top: 2em">
      <ProposedIssueItem
        v-for="(issue, index) in issues"
        :key="index"
        :issue="issue"
        @search="search($event)"
        @status_id_changed="change_status_id(issue, $event)"
        @open="opened_issue = index"
        @close="opened_issue = -1"
        :opened="opened_issue === index"
        v-show="opened_issue === -1 || opened_issue === index"
      />
    </div>
    <router-link to="/">
      {{ default_labels.return }}
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import SearchBox from "@/components/SearchBox.vue";
import ProposedIssueItem from "@/components/ProposedIssueItem.vue";
import Feedbacks from "@/components/Feedbacks.vue";
import { WSClient, ApiMessage } from "@/backend/WSClient";
import { ref, watch } from "vue";
import { useLocaleStore } from "@/locale/i18n_store";
import get_default_labels from "@/locale/default";
import { IssueLocalized } from '@/types/common';

const issues = ref([] as IssueLocalized[]),
  opened_issue = ref(-1),
  loading = ref(),
  messages = ref([] as ApiMessage[]),
  client = new WSClient(loading, messages),
  localeStore = useLocaleStore();

const search = function (searchString: Event | string | undefined) {
  opened_issue.value = -1;
  const tagsearch = searchString === undefined ? "" : (("&tags=" + searchString) as string);
  client
    .queryWs<{ issues: IssueLocalized[] }>("GET", "/issue?created_by=me&with_expired=true&lang=" + localeStore.locale + tagsearch)
    .then((apiResp) => {
      issues.value = apiResp.json.issues as IssueLocalized[];
    });
};

watch(
  () => localeStore.locale,
  (newLocale: string) => {
    search(undefined);
  },
  { immediate: true }
);

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const change_status_id = function (issue: IssueLocalized, status_id: number): void {
  issue.status_id = status_id
};

const default_labels = get_default_labels();
</script>
