<template>
  <div :direction="localeStore.direction">
    <header :style="localeStore.flex_row_direction">
      <a class="title-band" href="/">
        <div class="title" v-html="app_title"></div>
        <div class="subtitle">{{ default_labels.app_subtitle }}</div>
      </a>
      <div class="right-menu">
        <router-link class="profile-icon" to="/user_menu">
          <span v-if="sessionStore.user_initial">{{ sessionStore.user_initial }}</span>
          <font-awesome-icon v-else icon="user" />
        </router-link>
        <font-awesome-icon class="globe" icon="globe" @click.prevent="is_lang_openned = !is_lang_openned" />
      </div>
    </header>
    <LinkWarning
      v-if="show_email_warn"
      :text="default_labels.no_email"
      path="/profile"
      style="margin-top: 1em"
    ></LinkWarning>
    <div v-if="show_session_actions" style="max-width: 60rem; margin: 0 auto">
      <RemindForm v-if="sessionStore.action === 'remind'"></RemindForm>
      <RegisterForm v-else-if="sessionStore.action === 'register'"></RegisterForm>
      <LoginForm v-else></LoginForm>
    </div>
    <div v-else>
      <Feedbacks :messages="sessionStore.messages" @close="removeMessage($event)" style="margin: 2em 0"></Feedbacks>
      <suspense timeout="0">
        <template #default>
          <router-view v-slot="{ Component }">
            <transition name="route" mode="out-in">
              <component :is="Component"></component>
            </transition>
          </router-view>
        </template>
        <template #fallback>
          <div>Loading...</div>
        </template>
      </suspense>
    </div>
    <Popup v-if="is_lang_openned" :title="default_labels.language" @close="is_lang_openned = false">
      <LangSelector :lang="localeStore.locale" @change="change_locale" />
    </Popup>
    <footer>
      <router-link :to="{ name: 'Help' }">{{ default_labels.help }}</router-link> |
      <router-link :to="{ name: 'About' }"> Kassandr &copy; 2024</router-link>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import get_default_labels from "@/locale/default";
import { SupportedLanguages } from "@/locale/default";
import { useSessionStore, SessionStatus } from "@/backend/session";
import { useLocaleStore } from "@/locale/i18n_store";
import LoginForm from "@/views/session/LoginForm.vue";
import RegisterForm from "@/views/session/RegisterForm.vue";
import RemindForm from "@/views/session/RemindForm.vue";
import LinkWarning from "@/components/LinkWarning.vue";
import LangSelector from "@/components/LangSelector.vue";
import Popup from "@/components/Popup.vue";
import Feedbacks from "@/components/Feedbacks.vue";

const sessionStore = useSessionStore(),
  localeStore = useLocaleStore(),
  default_labels = get_default_labels(),
  route = useRoute(),
  is_lang_openned = ref(false);

if (sessionStore.logged_user) {
  localeStore.set_locale(sessionStore.logged_user.locale as SupportedLanguages);
}

const app_title = computed((): string => {
  return default_labels.value.app_title.replace("{0}", '<span class="sep">&diams;</span>');
});

const show_email_warn = computed((): boolean => {
  return sessionStore.connected === SessionStatus.Anonymous && route.name != "Profile";
});

const change_locale = (val: SupportedLanguages): void => {
  localeStore.locale = val;
  is_lang_openned.value = false;
};

const removeMessage = function (index: number): void {
  sessionStore.messages.splice(index, 1);
};

const show_session_actions = computed(() => {
  return sessionStore.action && route.name != "UserRecovery";
});
</script>

<style>
:root {
  --white: #ffffff;
  --gray-90: hsl(0, 0%, 90%);
  --gray-77: hsl(0, 0%, 77%);
  --gray-50: hsl(0, 0%, 50%);
  --gray-30: hsl(0, 0%, 30%);
  --gray-15: hsl(0, 0%, 15%);
  --gray-3: hsl(0, 0%, 3%);
  --fuschia-20: hsl(348, 20%, 82%);
  --fuschia-57: hsl(348, 57%, 82%);
  --fuschia-79: hsl(348, 79%, 49%);
  --green-light: hsl(80, 83%, 88%);
  --green-medium: hsl(108, 60%, 49%);
  --green-dark: hsl(86, 84%, 36%);
  --violet-light: hsl(295, 30%, 91%);
  --violet-medium: hsl(296, 60%, 64%);
  --violet-dark: hsl(296, 100%, 49%);
  --orange-light: hsl(33, 100%, 83%);
  --orange-medium: hsl(29, 100%, 58%);
  --orange-dark: hsl(24, 100%, 32%);
  --orange-darker: hsl(24, 100%, 20%);
  --blue-light: hsl(244, 30%, 91%);
  --blue-medium: hsl(244, 60%, 64%);
  --blue-dark: hsl(244, 98%, 36%);
  --font-main: Arial, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 10px;
}

#app {
  font-family: var(--font-main);
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
}

body {
  font-size: 1.6em;
  color: var(--gray-77);
  padding: 1em;
}

a {
  color: var(--fuschia-57);
}

a:active {
  color: var(--fuschia-79);
}

div[direction="rtl"] * {
  direction: rtl;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

header .title-band {
  margin-right: 1em;
  color: inherit;
  text-decoration: none;
}

header .title {
  font-size: 2.9rem;
  font-weight: bold;
  text-transform: uppercase;
}

header .title .sep {
  color: var(--fuschia-79);
  font-size: 0.5em;
  vertical-align: middle;
  position: relative;
  top: -0.2em;
  padding: 0 0.3em 0 0;
}

header .subtitle {
  color: var(--fuschia-57);
  font-style: italic;
  text-align: right;
}

header .profile-icon {
  border-radius: 50%;
  background-color: var(--fuschia-57);
  color: var(--white);
  padding: 1em;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

header .right-menu {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.globe {
  font-size: 3em;
  color: var(--fuschia-57);
}

.box {
  background: var(--gray-15);
  box-shadow: 0px 4px 10px #00000073, 0px 0px 10px 2px #8a8a8a99;
  border-radius: 5px;
  padding: 1.5em;
}

.tag {
  color: var(--fuschia-57);
  font-style: italic;
  margin-right: 0.4em;
}

.btn {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.875em;
  line-height: 2.3rem;
  text-align: center;
  letter-spacing: -0.02em;
  font-variant: all-petite-caps;
  border: 0;
  padding: 0.6em 2.5em;
  cursor: pointer;
}

.btn-primary {
  background: var(--fuschia-79);
  color: var(--white);
}

.btn-default {
  background: var(--gray-77);
  color: black;
}

.btn-bar {
  display: flex;
  margin-top: 1em;
  justify-content: space-evenly;
  margin-top: 2em;
  gap: 1rem;
  flex-wrap: wrap;
}

input[type="text"],
select {
  padding: 0.75em;
  border-radius: 0.5em;
}

option {
  padding: 0.75em;
}

.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

footer {
  text-align: right;
  margin: 1em 0;
}

@media screen and (min-width: 420px) {
  body {
    font-size: 1.4rem;
    padding: 3rem 1em;
  }

  header .title {
    padding-right: 3em;
  }
}
</style>
