<template>
  <div class="alert warning" v-html="html"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: String,
    path: String,
  },
  computed: {
    html: function () {
      return this.text
        ?.replace("{", '<a href="' + this.path + '">')
        .replace("}", "</a>");
    },
  },
});
</script>
