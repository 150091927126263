<template>
  <button :class="theClass" @click="clicked" :disabled="disabled" :type="type">
    <slot></slot>
    <LoadIndicator :show_indicator="show_indicator"></LoadIndicator>
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";
import LoadIndicator from "./LoadIndicator.vue";

type bType = "button" | "submit" | "reset";
const props = defineProps<{
  show_indicator: boolean;
  disabled?: boolean;
  type?: bType;
  class?: string;
}>();

const emit = defineEmits<{
  (e: "click", val: MouseEvent): void;
}>();

const clicked = ($event: MouseEvent) => {
  emit("click", $event);
};

const theClass = computed((): string => {
  let cls = "with-indicator " + (props.class ?? "");
  if (props.show_indicator) cls += " on";
  return cls;
});
</script>

<style>
button.with-indicator {
  position: relative;
  transition-timing-function: ease-in;
}

button.with-indicator.on {
  padding-right: 2.8em;
  transition: padding-right 0.3s;
}
</style>