<template>
  <div>
    <h1>Translate assistant</h1>
    <form>
      <textarea name="" id="" cols="30" rows="10" v-model="input_text"> </textarea>
      <button @click.prevent="translate_input">Start</button>
    </form>
    <div v-for="lang_section in proposed_translations">
      <h2>{{ lang_section.lang }}</h2>
      <div v-for="lang_section in proposed_translations">
        <div v-for="(value, propertyName, index) in lang_section.missing">
          <td>{{ propertyName + ": " + value }}</td>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { SupportedLanguages } from "@/locale/default";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore } from "@/backend/session";
import { DefaultLabels } from "@/locale/default";

const localeStore = useLocaleStore();
const sessionStore = useSessionStore();
sessionStore.require_session();

const supported_languages = ["en", "fr", "ru", "uk", "cn"];

interface TranslatedLang {
  lang: string;
  missing: Record<string, string>;
}

const current_lang = ref<SupportedLanguages>("fr"),
  input_text = ref(""),
  parse_errors = ref<string[]>([]),
  translated = ref<Record<string, string>>({}),
  proposed_translations = ref<TranslatedLang[]>([]);

const parse_input = (): Record<string, string> => {
  parse_errors.value = [];
  var lines = input_text.value.split(/\r?\n|\r|\n/g);
  const to_translate = {};
  lines.forEach((line) => {
    line = line.trim().replace(/,*$/, "");
    const regex = /([^:]+): "([^"]+)"/;
    const match = line.match(regex);
    if (match) {
      to_translate[match[1].trim()] = match[2].trim();
    } else {
      parse_errors.value.push(line);
    }
  });
  return to_translate;
};

const get_lang_translation = async (loc: SupportedLanguages) => {
  const mod = await import(`@/locale/lang/default_${loc}`);
  return mod.default;
};

const loop = (english: DefaultLabels, target: DefaultLabels) => {
  for (const [key, value] of Object.entries(english)) {
    if (target[key]) {
      if (target[key] === value) {
        console.log(`Identical ${key}: ${value}`);
      } else {
        console.log(`Ok ${key}`);
      }
    } else {
      console.log(`Missing ${key}: ${value}`);
    }
    translated.value[key] = target[key];
  }
};

const translate_input = async () => {
  const english = await get_lang_translation("en");

  const to_translate = parse_input();

  const res = supported_languages.map(async (langage): Promise<TranslatedLang> => {
    const already_translated = await get_lang_translation(langage as SupportedLanguages);
    let missing = {} as Record<string, string>;
    for (const [key, value] of Object.entries(to_translate)) {
      if (!already_translated[key]) {
        missing[key] = english[key];
      }
    }
    return { lang: langage, missing } as TranslatedLang;
  });
  proposed_translations.value = await Promise.all(res);
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}

table td,
table th {
  border: 1px solid white;
}
</style>
