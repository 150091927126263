<template>
  <div class="box issue-item" :style="item_style">
    <div :class="title_class">
      <h1>
        <router-link :to="'/q/' + props.issue.slug">{{ props.issue.title }}</router-link>
      </h1>
      <div class="labels">
        <span :class="issue_status" v-if="issue_status">{{ default_labels[issue_status] }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, CSSProperties } from "vue";
import { IssueLocalized } from "@/types/common";
import get_default_labels from "@/locale/default";

const props = defineProps<{
  issue: IssueLocalized;
  forecast?: IssueLocalized;
}>();

const emit = defineEmits<{
  (e: "search", value: string): void;
}>();

const tag_list = computed((): string[] => {
  const tl = props.issue?.tags;
  if (!tl) return [];
  return tl.split(" ");
});

const item_style = computed((): CSSProperties | undefined => {
  if (!props.issue.picture) return undefined;
  const rep = {} as CSSProperties;
  rep["background-image"] = "url(" + baseApi + "/picture/" + props.issue.picture + ")";
  rep["min-height"] = "15rem";
  return rep;
});

const default_labels = get_default_labels();

const title_class = computed((): string[] => {
  return props.issue.picture ? ["title", "picture"] : ["title"];
});

const issue_status = computed((): string => {
  const fo = props.issue.final_outcome;
  const co = props.issue.forecast_outcome;
  if (co != null) {
    if (fo != null) {
      if (fo == co) {
        return "issue_won_short";
      }
      return "issue_lost_short";
    }
    return "forecasted";
  }
  if (fo != null) {
    return "closed";
  }
  return "";
});

const baseApi = (import.meta.env.VITE_SERVER_BASE || "") + (import.meta.env.VITE_API || "/api");
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.issue-item {
  position: relative;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem 0 0;
  background-size: 100%;
}

.issue-item .title {
  padding: 0.5rem 1.5rem;
}

.issue-item .title.picture {
  background-color: rgba(0, 0, 0, 0.7);
}

.issue-item h1 {
  font-size: 1.3em;
  margin-top: 0;
}

.issue-item a {
  text-decoration: none;
}

.issue-item a:hover {
  text-decoration: underline;
}

.issue-item .labels {
  display: flex;
  gap: 1em;
}

.issue-item .closed {
  color: var(--violet-dark);
}

.issue-item .forecasted {
  color: var(--blue-light);
}

.issue-item .issue_won_short {
  color: var(--green-medium);
}

.issue-item .issue_lost_short {
  color: var(--fuschia-79);
}

</style>
