<template>
  <DoughnutChart v-bind="doughnutChartProps" />
</template>

<script lang="ts">
import { computed, PropType } from "vue";
import { DoughnutChart, useDoughnutChart } from "vue-chart-3";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import { defineComponent } from "vue";

Chart.register(...registerables);

export default defineComponent({
  name: "IssueSummaryChart",
  components: { DoughnutChart },
});
</script>

<script lang="ts" setup>
const props = defineProps({
  counts: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
  colors: {
    type: Array as PropType<Array<string>>,
    required: true,
  },
  labels: {
    type: Array as PropType<Array<string>>,
  },
});

const testData = computed<ChartData<"doughnut">>(() => {
  return {
    labels: props.labels,
    datasets: [
      {
        data: props.counts,
        backgroundColor: props.colors,
      },
    ],
  };
});

const options = computed<ChartOptions<"doughnut">>(() => ({
  plugins: {
    legend: {
      display: false,
    },
  },
}));

const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
  chartData: testData,
  options,
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
