<template>
  <div class="search-box">
    <font-awesome-icon icon="search" style="color: var(--gray-15)" />
    <input type="text" :value="value" @change="on_change"/>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';

const props = defineProps<{
  msg?: string;
  value?: string;
}>();

const emit = defineEmits<{
  (e: "change", value: string): void;
}>();

const on_change = ($event: Event) => {
  const target = $event.target as HTMLInputElement;
  emit('change', target.value);
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-box {
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  padding-right: 5px;
  width: min(25em, 100%);
}

.search-box:focus-within {
  outline: 1px solid var(--fuschia-79);
}

.search-box svg {
  color: var(--gray-15);
  margin: 0 0.4em;
  cursor: pointer;
}

.search-box input[type="text"] {
  flex-grow: 1;
  padding: 0.5em;
  border: 0;
  outline: none;
}
</style>
