<template>
  <div class="page-editor">
    <h1>{{ default_labels.edit_page }}</h1>
    <Feedbacks
      v-if="messages && messages.length > 0"
      :messages="messages"
      @close="removeMessage($event)"
      style="margin-bottom: 1em"
    />
    <nav id="locales">
      <button
        v-for="loccode in existing_locales"
        :class="loccode == page.locale ? 'btn btn-primary' : 'btn btn-default'"
        @click.prevent="change_locale(loccode)"
      >
        {{ loccode }}
      </button>
    </nav>
    <form @submit.prevent="update_page" ref="form" style="margin-bottom: 4rem">
      <div class="sections">
        <section id="question-section">
          <h2>{{ default_labels.content }}</h2>
          <div class="content">
            <fieldset>
              <div class="fields">
                <label for="question" style="padding-top: 0">{{ default_labels.title }}</label>
                <input
                  id="question"
                  type="text"
                  :placeholder="default_labels.question_example"
                  v-model="page.title"
                  required
                />
              </div>
              <div class="help">
                {{ default_labels.page_title_explain }}
              </div>
            </fieldset>
            <fieldset>
              <div class="fields">
                <label for="description">{{ default_labels.content }}</label>
                <EditableRichtext
                  id="description"
                  :modelValue="page.content"
                  @update:modelValue="page.content = $event"
                  :class="desc_error ? 'error' : ''"
                  @input="desc_error = false"
                ></EditableRichtext>
              </div>
              <div class="help">
                {{ default_labels.page_content_explain }}
              </div>
            </fieldset>
            <fieldset>
              <div class="fields">
                <label for="slug">{{ default_labels.slug }}</label>
                <input id="slug" type="text" :placeholder="default_labels.slug" v-model="page.slug" required />
              </div>
              <div class="help">
                {{ default_labels.slug_explain }}
              </div>
            </fieldset>
          </div>
        </section>
        <section id="picture">
          <h2>{{ default_labels.picture }}</h2>
          <div class="content">
            <fieldset>
              <div class="fields">
                <img v-if="picture" :src="picture" alt="preview" />
                <div class="picture_tools">
                  <input id="picture" type="file" @change="onPictureChanged($event)" accept="image/*" />
                  <a v-if="picture" @click.prevent="delete_picture" href="#"><font-awesome-icon icon="trash" /></a>
                </div>
              </div>
              <div class="help">
                {{ default_labels.picture_explain }}
              </div>
            </fieldset>
          </div>
        </section>
      </div>

      <div style="text-align: center; margin-bottom: 4rem">
        <ButtonWithIndicator type="submit" :show_indicator="loading" class="btn btn-primary">{{
          default_labels.save
        }}</ButtonWithIndicator>
      </div>
    </form>
    <NewPageLocaleForm
      v-if="page.slug"
      :slug="page.slug"
      :source_locale="page.locale"
      @created="new_locale($event)"
      style="margin-bottom: 1em"
    ></NewPageLocaleForm>
    <div style="text-align: center; margin-bottom: 4rem">
      <router-link to="/page/list">
        {{ default_labels.return }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { WSClient, ApiMessage } from "@/backend/WSClient";
import { Page } from "@/types/common";
import { ref, watch, watchEffect } from "vue";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore } from "@/backend/session";
import get_default_labels from "@/locale/default";
import { useRoute } from "vue-router";
import router from "@/router";
import { Duration, firstArg } from "@/backend/utils";
import Feedbacks from "@/components/Feedbacks.vue";
import { toast } from "vue3-toastify";
import NewPageLocaleForm from "@/views/page/NewPageLocaleForm.vue";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";
import EditableRichtext from "@/components/EditableRichtext.vue";

const lorem = ref(
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu  fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
);

const localeStore = useLocaleStore(),
  sessionStore = useSessionStore(),
  route = useRoute(),
  default_labels = get_default_labels(),
  messages = ref([] as ApiMessage[]),
  loading = ref(false),
  desc_error = ref(false),
  existing_locales = ref([] as string[]);

const client = new WSClient(loading, messages);
const page = ref({} as Page);

const new_page = async () => {
  page.value = {
    locale: localeStore.locale,
    slug: "",
    title: "",
    content: "",
  } as Page;
};

const load_page = async (slug: string, lang: string) => {
  const resp = await client.queryWs<{ page: Page }>("GET", "/page/" + slug, {
    lang: lang || "en",
  });
  const page_obj = resp.json.page;

  page.value = {
    locale: page_obj.locale,
    slug: page_obj.slug,
    title: page_obj.title,
    content: page_obj.content,
  };

  picture.value = page_obj.picture ? "/api/picture/" + page_obj.picture : null;

  const locale_list_resp = await client.queryWs<{ locales: string[] }>(
    "GET",
    "/page/" + page.value.slug + "/locale_list"
  );
  existing_locales.value = locale_list_resp.json.locales;
};

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const dataURLToBlob = (dataURL): Blob => {
  var BASE64_MARKER = ";base64,";
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    var parts = dataURL.split(",");
    var contentType = parts[0].split(":")[1];
    var raw = parts[1];

    return new Blob([raw], { type: contentType });
  }

  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(":")[1];
  var raw2 = window.atob(parts[1]);
  var rawLength = raw2.length;

  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw2.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

const update_page = async () => {
  if (!page.value.content) {
    desc_error.value = true;
  }

  const saved = await client.queryWs<{ issue_id: number }>("POST", "/page", null, {
    page: page.value,
  });

  if (picture.value) {
    const resizedImage = dataURLToBlob(picture.value);
    await client.postMedia("/picture/" + saved.json.issue_id, null, "picture", resizedImage);
  }

  toast(default_labels.value.success, { autoClose: 1000 });
};

const picture = ref(); // picture.value
const onPictureChanged = ($event: Event) => {
  const target = $event.target as HTMLInputElement;
  if (target === null || target.files === null || target.files.length === 0) {
    console.warn("No image from target");
    return;
  }
  const file = target.files[0];
  if (!file.type.match(/image.*/)) {
    console.warn("Loaded file is not an image");
  }

  var reader = new FileReader();
  var image = new Image();
  image.onload = function (imageEvent) {
    // Resize the image
    var canvas = document.createElement("canvas"),
      max_size = 1300,
      width = image.width,
      height = image.height;
    if (width > height) {
      if (width > max_size) {
        height *= max_size / width;
        width = max_size;
      }
    } else {
      if (height > max_size) {
        width *= max_size / height;
        height = max_size;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, width, height);
    picture.value = canvas.toDataURL(file.type);
  };
  reader.onload = function (readerEvent) {
    image.src = reader.result as string;
  };

  reader.readAsDataURL(file);
};

const delete_picture = async () => {
  await client.queryWs<{ issue_id: number }>("DELETE", "/picture/" + page.value.slug);
  picture.value = undefined;
};

const new_locale = async (page: Page) => {
  existing_locales.value.push(page.locale);
  router.push({ params: { slug: page.slug }, query: { lang: page.locale } });
};

const change_locale = (locale: string) => {
  router.push({ params: { slug: page.value.slug }, query: { lang: locale } });
};

sessionStore.require_session();

watchEffect(() => {
  const slug = firstArg(route.params.slug);
  const lang = firstArg(route.query.lang);

  if (slug === undefined || slug === null || slug === "new") {
    new_page();
  } else {
    load_page(slug, lang ?? localeStore.locale);
  }
});

watch(
  () => route.query.lang,
  (lang_arg) => {
    const slug = firstArg(route.params.slug);
    const lang = firstArg(lang_arg);
    if (slug && lang) load_page(slug, lang ?? localeStore.locale);
  }
);
</script>

<style>
.page-editor {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.page-editor form .sections {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 2em 0 4em;
}

.page-editor form fieldset {
  margin-bottom: 1em;
  border: 0;
}

.page-editor form fieldset .fields {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.page-editor form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  align-items: center;
}

.page-editor label {
  padding: 0.75em 0;
}

.page-editor .outcome {
  display: flex;
  align-items: center;
  gap: 1em;
  align-items: stretch;
  margin-bottom: 0.5em;
}

.page-editor .icon {
  cursor: pointer;
  font-size: 1.3em;
}

.page-editor .outcome .del.icon {
  color: var(--fuschia-79);
  align-self: center;
}

.page-editor .outcome .offset {
  width: 3em;
}

.error {
  border: 1px solid var(--fuschia-79);
}

.picture_tools {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
}

.date-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

nav#locales {
  display: flex;
  flex-wrap: warp;
  gap: 1em;
}

@media screen and (min-width: 480px) {
  .page-editor form .sections section {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .page-editor form fieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
  }

  .page-editor form fieldset .help {
    padding-top: 2.5em;
  }
}
</style>
