<template>
  <input
    :type="date_type"
    :value="cutDate(props.modelValue)"
    v-on:input="updateValue($event)"
    :required="props.required"
  />
</template>

<script lang="ts" setup>

import { computed } from "vue";

const props = defineProps<{
  modelValue: string | null | undefined;
  required?: boolean,
  use_hours?: boolean,
}>();

const emit = defineEmits(["update:modelValue"]);

const date_type = computed((): string =>{return props.use_hours ? "datetime-local" : "date"});

function cutDate(date: string | null | undefined): string {
  if(date == null || date == undefined) return "";
  const d = new Date(date);
  var full_str = "" + d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    ("0" + d.getDate()).slice(-2);
    if(props.use_hours)
    full_str += ("T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2));
  console.log("Init localdate with " + full_str);
  return full_str;
}

function updateValue(event: Event) {
  let input = (event.target as HTMLInputElement);
  if (input.value) {
    const date_val = new Date(input.value);
    const date_utc_str = date_val.toISOString();
    emit("update:modelValue", date_utc_str);
    console.log("Model changed to " + date_utc_str);
    return;
  }
  emit("update:modelValue", null);
}
</script>
