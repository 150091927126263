<template>
  <form @submit.prevent="register">
    <h1>{{ default_labels.register }}</h1>
    <Feedbacks :messages="sessionStore.messages" @close="removeSessionMessage($event)"></Feedbacks>
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />
    <p style="margin-top: 0">{{ default_labels.please_register }}</p>
    <div class="input-group">
      <label for="name">{{ default_labels.display_name }}</label>
      <input id="name" type="text" :placeholder="default_labels.display_name" required v-model="name" />
    </div>
    <div class="input-group">
      <label for="email">{{ default_labels.email }}</label>
      <input id="email" type="text" :placeholder="default_labels.email" required v-model="email" />
    </div>
    <div class="btn-bar" :style="localeStore.flex_row_direction">
      <ButtonWithIndicator type="submit" :show_indicator="loading" class="btn btn-primary">{{
        default_labels.create
      }}</ButtonWithIndicator>
      <button class="btn btn-default" @click="sessionStore.action = 'login'">
        {{ default_labels.already_registered }}
      </button>
    </div>
    <div style="margin-top: 1.5em; text-align: center">
      <a href="" @click.prevent="sessionStore.action = null">{{ default_labels.return }}</a>
    </div>
  </form>
</template>

<script lang="ts" setup>
import Feedbacks from "@/components/Feedbacks.vue";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";
import { ref, inject } from "vue";
import get_default_labels from "@/locale/default";
import { WSClient, ApiMessage } from "@/backend/WSClient";
import { useSessionStore } from "@/backend/session";
import { useLocaleStore } from "@/locale/i18n_store";
import { User } from "@/types/common";
import { useGtm } from "@gtm-support/vue-gtm";
import { EMAIL_TAKEN_ERROR } from "@/types/errors";

const sessionStore = useSessionStore(),
  localeStore = useLocaleStore(),
  mixpanel = inject("mixpanel") as any,
  gtm = useGtm();

const loading = ref(false),
  messages = ref([] as ApiMessage[]),
  client = new WSClient(loading, messages),
  name = ref(""),
  email = ref("");

const default_labels = get_default_labels();

function register(): void {
  mixpanel.track("submit_register");
  gtm?.trackEvent({
    event: "submit register",
    category: "session",
    action: "click",
  });
  client
    .queryWs<{ token: string }>("POST", "/register", null, {
      locale: localeStore.locale || "en",
      name: name.value,
      email: email.value,
    })
    .then((apiResp) => {
      mixpanel.track("submit_register_ok");
      sessionStore.token = apiResp.json.token;
      sessionStore.logged_user = { name: name.value, email: email.value } as User;
      sessionStore.action = null;
      sessionStore.messages = [{ level: "info", text: default_labels.value.welcome.replace("{0}", name.value) }];
      gtm?.trackEvent({
        event: "register ok",
        category: "session",
        action: "response",
        
      });
    })
    .catch((reason) => {
      mixpanel.track("submit_register_error");
      if (reason.status === EMAIL_TAKEN_ERROR) {
        messages.value = [{ level: "error", text: default_labels.value.email_taken_error }];
      }
    });
}

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};
const removeSessionMessage = function (index: number): void {
  sessionStore.messages.splice(index, 1);
};
mixpanel.track("show_register_form");
gtm?.trackEvent({
  event: "show register",
  category: "session",
  action: "view",
  noninteraction: true
});
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 30em;
}

.input-group {
  padding: 0.5em 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
}

Label {
  display: block;
  margin-bottom: 0.3em;
}

input[type="text"] {
  width: min(25em, 100%);
}
</style>
