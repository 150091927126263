<template>
  <div class="issue-editor">
    <h1>{{ default_labels.propose_one_issue }}</h1>
    <Feedbacks
      v-if="messages && messages.length > 0"
      :messages="messages"
      @close="removeMessage($event)"
      style="margin-bottom: 1em"
    />
    <nav id="locales">
      <button
        v-for="loccode in existing_locales"
        :class="loccode == issue_locale.locale ? 'btn btn-primary' : 'btn btn-default'"
        @click.prevent="change_locale(loccode)"
      >
        {{ loccode }}
      </button>
    </nav>
    <form @submit.prevent="update_issue" ref="form" style="margin-bottom: 4rem">
      <div class="sections">
        <section id="question-section">
          <h2>{{ default_labels.question }}</h2>
          <div class="content">
            <fieldset>
              <div class="fields">
                <label for="question" style="padding-top: 0">{{ default_labels.question }}</label>
                <input
                  id="question"
                  type="text"
                  :placeholder="default_labels.question_example"
                  v-model="issue_locale.title"
                  required
                />
              </div>
              <div class="help">
                {{ default_labels.question_explain }}
              </div>
            </fieldset>
            <fieldset>
              <div class="fields">
                <label for="description">{{ default_labels.description }}</label>
                <textarea
                  id="description"
                  type="text"
                  :placeholder="default_labels.description"
                  v-model="issue_locale.description"
                  rows="5"
                  :class="desc_error ? 'error' : ''"
                  @input="desc_error = false"
                ></textarea>
              </div>
              <div class="help">
                {{ default_labels.description_explain }}
              </div>
            </fieldset>
            <fieldset>
              <div class="fields">
                <label for="slug">{{ default_labels.slug }}</label>
                <input id="slug" type="text" :placeholder="default_labels.slug" v-model="issue_locale.slug" required />
              </div>
              <div class="help">
                {{ default_labels.slug_explain }}
              </div>
            </fieldset>
            <fieldset>
              <div class="fields">
                <label for="tags">{{ default_labels.tags }}</label>
                <div>
                  <input id="tags" type="text" :placeholder="default_labels.tags" v-model="issue_locale.tags" />
                </div>
              </div>
              <div class="help">
                {{ default_labels.tags_explain }}
              </div>
            </fieldset>
          </div>
        </section>
        <section id="outcomes">
          <h2>{{ default_labels.outcomes }}</h2>
          <div class="content">
            <fieldset>
              <div class="fields">
                <div v-for="(outcome, index) in issue.outcomes" :key="index" class="outcome">
                  <ConditionTypeSelector
                    :modelValue="outcome.condition_type"
                    @change="outcome.condition_type = $event"
                  />
                  <DateTimeEditor
                    v-if="is_type(outcome, ConditionType.Before) || outcome.ends_on"
                    v-model="outcome.ends_on" :use_hours="use_hours"
                  />
                  <input
                    v-else-if="is_type(outcome, ConditionType.StringValue)"
                    type="text"
                    :placeholder="default_labels.stringvalue"
                    v-model="outcome.label"
                  />
                  <input class="offset" type="number" :placeholder="default_labels.offset" v-model="outcome.offset" />
                  <font-awesome-icon class="del icon" icon="times" @click.prevent="remove_outcome(index)" />
                </div>
                <div style="margin-top: 1em">
                  <font-awesome-icon
                    class="icon"
                    icon="circle-plus"
                    @click.prevent="add_outcome"
                    style="color: var(--fuschia-20)"
                  />
                </div>
              </div>
              <div class="help">
                {{ default_labels.outcomes_explain }}
              </div>
            </fieldset>
          </div>
        </section>
        <section id="delays">
          <h2>{{ default_labels.timeframe }}</h2>
          <div class="content">
            <fieldset>
              <div class="fields">
                <div class="date-grid">
                  <label>{{ default_labels.starts_on }}</label>
                  <DateTimeEditor v-model="issue.starts_on" :required="true" :use_hours="use_hours"/>

                  <label>{{ default_labels.to_end_on }}</label>
                  <DateTimeEditor v-model="issue.ends_on" :required="true" :use_hours="use_hours" />

                  <label>{{ default_labels.delay }}</label>
                  <div style="display: flex; gap: 0.5em">
                    <input
                      type="number"
                      v-model="delay_duration.value"
                      style="width: 5em; text-align: right"
                      min="1"
                      required
                    />
                    <select v-model="delay_duration.unit">
                      <option value="hour">{{ default_labels.hours }}</option>
                      <option value="day">{{ default_labels.days }}</option>
                      <option value="week">{{ default_labels.weeks }}</option>
                      <option value="year">{{ default_labels.years }}</option>
                    </select>
                    <span style="align-self: center">{{ default_labels.hours }}</span>
                  </div>
                </div>
                <div
                style="margin: 1.5em 0"><input type="checkbox" id="use_hours" v-model="use_hours"/> <label for="use_hours">{{ default_labels.hours }}</label></div>
              </div>
              <div class="help">
                {{ timeframe_explain }}
              </div>
            </fieldset>
          </div>
        </section>
        <section id="picture">
          <h2>{{ default_labels.picture }}</h2>
          <div class="content">
            <fieldset>
              <div class="fields">
                <img v-if="picture" :src="picture" alt="preview" />
                <div class="picture_tools">
                  <input id="picture" type="file" @change="onPictureChanged($event)" accept="image/*" />
                  <a v-if="picture" @click.prevent="delete_picture" href="#"><font-awesome-icon icon="trash" /></a>
                </div>
              </div>
              <div class="help">
                {{ default_labels.picture_explain }}
              </div>
            </fieldset>
          </div>
        </section>
      </div>

      <div style="text-align: center; margin-bottom: 4rem">
        <ButtonWithIndicator type="submit" :show_indicator="loading" class="btn btn-primary">{{
          default_labels.save
        }}</ButtonWithIndicator>
      </div>
    </form>
    <NewIssueLocaleForm
      v-if="issue.issue_id"
      :issue_id="issue.issue_id"
      :source_locale="issue_locale.locale"
      @created="new_locale($event)"
      style="margin-bottom: 1em"
    ></NewIssueLocaleForm>
    <div style="text-align: center; margin-bottom: 4rem">
      <router-link to="/proposed_issue/list">
        {{ default_labels.return }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ConditionType } from "@/types/common";
import { WSClient, ApiMessage } from "@/backend/WSClient";
import { Issue, IssueLocale, IssueLocalized, Outcome } from "@/types/common";
import { computed, ref, watch, watchEffect } from "vue";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore } from "@/backend/session";
import get_default_labels from "@/locale/default";
import { useRoute } from "vue-router";
import router from "@/router";
import { Duration, hours_to_duration, duration_to_hours, firstArg } from "@/backend/utils";
import DateTimeEditor from "@/components/DateTimeEditor.vue";
import ConditionTypeSelector from "@/components/ConditionTypeSelector.vue";
import Feedbacks from "@/components/Feedbacks.vue";
import NewIssueLocaleForm from "@/views/issue/NewIssueLocaleForm.vue";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";
import { toast } from "vue3-toastify";

const lorem = ref(
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu  fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
);

const localeStore = useLocaleStore(),
  sessionStore = useSessionStore(),
  route = useRoute(),
  default_labels = get_default_labels(),
  messages = ref([] as ApiMessage[]),
  loading = ref(false),
  desc_error = ref(false),
  delay_duration = ref({ value: 24, unit: "hour" } as Duration),
  existing_locales = ref([] as string[]),
  use_hours = ref(false);

const client = new WSClient(loading, messages);
const issue = ref({} as Issue);
const issue_locale = ref({} as IssueLocale);

const new_issue = async () => {
  const date_format = (input: Date): string => {
    return input.toISOString().slice(0, 10) + "T" + input.toLocaleTimeString() + "Z";
  };

  const start = new Date(),
    end = new Date();
  end.setDate(end.getDate() + 7);

  issue.value = {
    delay: 24,
    starts_on: date_format(start),
    ends_on: date_format(end),
    outcomes: [{ condition_type: ConditionType.Other }] as Outcome[],
  } as Issue;

  issue_locale.value = {
    locale: localeStore.locale,
    slug: "",
    title: "",
    tags: "",
    description: "",
  } as IssueLocale;
};

const load_issue = async (id: number, lang: string) => {
  const resp = await client.queryWs<{ issue: IssueLocalized }>("GET", "/proposed_issue/" + id, {
    lang: lang || "en",
  });
  const il = resp.json.issue;

  issue.value = {
    issue_id: il.issue_id,
    creator_id: il.creator_id,
    delay: il.delay,
    created: il.created,
    starts_on: il.starts_on,
    ends_on: il.ends_on,
    ended_on: il.ended_on,
    final_outcome: il.final_outcome ?? undefined,
    outcomes: il.outcomes,
    status_id: il.status_id ?? 0,
  };

  delay_duration.value = hours_to_duration(issue.value.delay / 3600);

  issue_locale.value = {
    issue_id: il.issue_id,
    locale: il.locale,
    slug: il.slug,
    title: il.title,
    tags: il.tags,
    description: il.description,
  };

  picture.value = il.picture ? "/api/picture/" + il.picture : null;

  const locale_list_resp = await client.queryWs<{ locales: string[] }>("GET", "/issue/" + il.issue_id + "/locale_list");
  existing_locales.value = locale_list_resp.json.locales;
};

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const remove_outcome = function (index: number): void {
  issue.value.outcomes.splice(index, 1);
};

const add_outcome = function (): void {
  issue.value.outcomes.push({} as Outcome);
};

const is_type = function (outcome: Outcome, type: ConditionType): boolean {
  return outcome.condition_type === type;
};

const timeframe_explain = computed((): string => {
  return (
    default_labels.value.timeframe_explain &&
    default_labels.value.timeframe_explain.replace("{0}", Intl.DateTimeFormat().resolvedOptions().timeZone)
  );
});

const dataURLToBlob = (dataURL): Blob => {
  var BASE64_MARKER = ";base64,";
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    var parts = dataURL.split(",");
    var contentType = parts[0].split(":")[1];
    var raw = parts[1];

    return new Blob([raw], { type: contentType });
  }

  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(":")[1];
  var raw2 = window.atob(parts[1]);
  var rawLength = raw2.length;

  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw2.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

const update_issue = async () => {
  if (!issue.value.starts_on || !issue.value.ends_on) {
    return;
  }

  if (issue.value.starts_on > issue.value.ends_on) {
    messages.value = [{ level: "error", text: default_labels.value.inverted_start_end }];
    return;
  }

  if (!issue_locale.value.description) {
    desc_error.value = true;
  }

  if (issue.value.outcomes.length < 2) {
    messages.value = [{ level: "error", text: default_labels.value.minimum_outcomes }];
    return;
  }

  const post_issue = { ...issue.value },
    post_issue_locale = { ...issue_locale.value };
  post_issue_locale.outcomes_locale = { outcome_labels: [] };

  let outcome_labels = [] as string[];
  post_issue.outcomes.forEach((outcome) => {
    if (outcome.condition_type !== ConditionType.Before && outcome.condition_type !== ConditionType.After) {
      outcome.ends_on = undefined;
    }

    if (outcome.label) {
      outcome_labels.push(outcome.label);
      outcome.label = undefined;
    }
  });
  if (outcome_labels.length > 0) post_issue_locale.outcomes_locale = { outcome_labels };

  post_issue.delay = duration_to_hours(delay_duration.value) * 3600;
  const saved = await client.queryWs<{ issue_id: number }>("POST", "/issue", null, {
    issue: post_issue,
    issue_locale: post_issue_locale,
  });

  if (picture.value) {
    const resizedImage = dataURLToBlob(picture.value);
    await client.postMedia("/picture/" + saved.json.issue_id, null, "picture", resizedImage);
  }

  toast(default_labels.value.success, { autoClose: 1000 });
};

const picture = ref(); // picture.value
const onPictureChanged = ($event: Event) => {
  const target = $event.target as HTMLInputElement;
  if (target === null || target.files === null || target.files.length === 0) {
    console.warn("No image from target");
    return;
  }
  const file = target.files[0];
  if (!file.type.match(/image.*/)) {
    console.warn("Loaded file is not an image");
  }

  var reader = new FileReader();
  var image = new Image();
  image.onload = function (imageEvent) {
    // Resize the image
    var canvas = document.createElement("canvas"),
      max_size = 1300,
      width = image.width,
      height = image.height;
    if (width > height) {
      if (width > max_size) {
        height *= max_size / width;
        width = max_size;
      }
    } else {
      if (height > max_size) {
        width *= max_size / height;
        height = max_size;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, width, height);
    picture.value = canvas.toDataURL(file.type);
  };
  reader.onload = function (readerEvent) {
    image.src = reader.result as string;
  };

  reader.readAsDataURL(file);
};

const delete_picture = async () => {
  await client.queryWs<{ issue_id: number }>("DELETE", "/picture/" + issue.value.issue_id);
  picture.value = undefined;
};

const new_locale = async (issue_locale: IssueLocale) => {
  existing_locales.value.push(issue_locale.locale);
  router.push({ params: { slug: issue_locale.slug }, query: { lang: issue_locale.locale } });
};

const change_locale = (locale: string) => {
  router.push({ params: { slug: issue_locale.value.slug }, query: { lang: locale } });
};

sessionStore.require_session();

watchEffect(() => {
  const id = firstArg(route.params.id);
  const lang = firstArg(route.query.lang);

  if (id === undefined || id === "new") {
    new_issue();
  } else {
    load_issue(parseInt(id), lang ?? localeStore.locale);
  }
});

watch(
  () => route.query.lang,
  (lang) => {
    const id = firstArg(route.params.id);
    const loc = firstArg(lang);
    if (id && loc) load_issue(parseInt(id), loc);
  }
);
</script>

<style>
.issue-editor {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.issue-editor form .sections {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 2em 0 4em;
}

.issue-editor form fieldset {
  margin-bottom: 1em;
  border: 0;
}

.issue-editor form fieldset .fields {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.issue-editor form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  align-items: center;
}

.issue-editor label {
  padding: 0.75em 0;
}

.issue-editor .outcome {
  display: flex;
  align-items: center;
  gap: 1em;
  align-items: stretch;
  margin-bottom: 0.5em;
}

.issue-editor .icon {
  cursor: pointer;
  font-size: 1.3em;
}

.issue-editor .outcome .del.icon {
  color: var(--fuschia-79);
  align-self: center;
}

.issue-editor .outcome .offset {
  width: 3em;
}

.error {
  border: 1px solid var(--fuschia-79);
}

.picture_tools {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
}

.date-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

nav#locales {
  display: flex;
  flex-wrap: warp;
  gap: 1em;
}

@media screen and (min-width: 480px) {
  .issue-editor form .sections section {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .issue-editor form fieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
  }

  .issue-editor form fieldset .help {
    padding-top: 2.5em;
  }
}
</style>
