<template>
  <div class="lds-ring">
    <div :style="stl"></div>
    <div :style="stl"></div>
    <div :style="stl"></div>
    <div :style="stl"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Loader",
  props: {
    color: String,
    width: String,
  },
  computed: {
    stl: function () {
      return (
        "border: " +
        this.width +
        " solid " +
        (this.color || "white") +
        "; border-color: " +
        (this.color || "white") +
        " transparent transparent transparent;"
      );
    },
  },
});
</script>

<style>
.lds-ring {
  display: inline-block;
  position: relative;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 75%;
  height: 75%;
  margin: 10%;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
