<template>
  <div :class="is_closed ? 'issue closed' : 'issue'">
    <Feedbacks v-if="messages && messages.length > 0" :messages="messages" @close="removeMessage($event)" />
    <Loader class="loader" width="1.5rem" v-show="loading"></Loader>
    <div class="box issue-item">
      <font-awesome-icon class="share" icon="share-alt" @click.prevent="share" />
      <div class="tags">
        <a class="tag" v-for="(tag, index) in tag_list" :key="index" :href="'/?tag=' + tag"
          >#{{ tag }}</a
        >
      </div>
      <h1>{{ default_labels.status }}: {{ issue.title }}</h1>
      <p>{{ forecast_instruction }}</p>
      <div>
        <div style="margin-bottom: 2em" v-html="description_formated"></div>
        <div class="body">
          <OutcomeList class="outcomes" :issue="issue" :is_closed="is_closed" :choosed_outcome="issue.forecast_outcome" @choose_outcome="final_outcome = $event"></OutcomeList>
          <IssueSummaryChart v-if="issue.issue_id" :counts="chart_data" :labels="chart_labels" :colors="chart_colors" />
        </div>
      </div>
      <div class="btn-bar">
        <button class="btn" @click.prevent="$router.go(-1)">
          {{ default_labels.return }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nl2p, hours_to_duration } from "@/backend/utils";
import Feedbacks from "@/components/Feedbacks.vue";
import Loader from "@/components/Loader.vue";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { WSClient, ApiMessage } from "@/backend/WSClient";
import IssueSummaryChart from "@/components/IssueSummaryChart.vue";
import { Outcome, OutcomeStat } from "@/types/common";
import { useLocaleStore } from "@/locale/i18n_store";
import { useSessionStore, SessionStatus } from "@/backend/session";
import { firstArg } from "@/backend/utils";
import { outcome_short_label, defined_colors } from "@/backend/outcome";
import { useRoute } from "vue-router";
import { computed, ref, watch, CSSProperties } from "vue";
import get_default_labels from "@/locale/default";
import { IssueLocalized } from "@/types/common";
import OutcomeList from "./OutcomeList.vue";

const issue = ref({
    description: "",
    title: "",
  } as IssueLocalized),
  final_outcome = ref<undefined | number>(undefined),
  ended_on = ref(""),
  loading = ref(false),
  messages = ref([] as ApiMessage[]),
  route = useRoute(),
  localeStore = useLocaleStore(),
  sessionStore = useSessionStore(),
  outcome_stat = ref([] as OutcomeStat[]),
  default_labels = get_default_labels(),
  client = new WSClient(loading, messages),
  datetime_format = localeStore.datetime_format,
  relative_datetime_format = localeStore.relative_datetime_format;

const load_issue = (slugs: string | string[]): Promise<IssueLocalized | null> => {
  const slug = slugs instanceof Array ? slugs[0] : slugs;

  return client
    .queryWs<{ issue: IssueLocalized }>("GET", "/issue/" + slug + "?lang=" + localeStore.locale)
    .then((resp): IssueLocalized | null => {
      return resp.json.issue ?? null;
    });
};

const load_stat = async function (issue_id: number) {
  return await client
    .queryWs<{ outcomes: OutcomeStat[] }>("GET", "/issue/" + issue_id + "/forecast_summary")
    .then((apiResp) => {
      outcome_stat.value = apiResp.json.outcomes;
    });
};

const load_all = function (slug: string | undefined): void {
  if (slug === undefined) return;
  load_issue(slug).then((issue_ret) => {
    if (issue_ret === null || !issue_ret?.issue_id) return;
    issue.value = issue_ret;
    document.querySelectorAll('meta[name^="og:"').forEach((n) => {
      n.remove();
    });
    load_stat(issue_ret.issue_id);
  });
};

const description_formated = computed((): string => {
  return nl2p(issue.value.description);
});

const chart_data = computed((): number[] => {
  if (outcome_stat.value.length === 0) return [];
  let rep = Array(issue.value.outcomes.length).fill(0) as number[];
  for (let index = 0; index < outcome_stat.value.length; index++) {
    const row = outcome_stat.value[index] as OutcomeStat;
    rep[row.outcome] = row.count;
  }
  return rep as number[];
});

const chart_labels = computed((): string[] => {
  return issue.value.outcomes.map((outcome: Outcome) => {return outcome_short_label(outcome, relative_datetime_format)});
});

const chart_colors = computed((): string[] => {
  return defined_colors.slice(0, chart_data.value.length);
});

const close_issue = async function (): Promise<void> {
  if (sessionStore.connected != SessionStatus.Authenticated) {
    sessionStore.action = "register";
    sessionStore.messages = [{ level: "warning", text: default_labels.value.should_be_auth } as ApiMessage];
    return;
  }

  client
    .queryWs<{}>("PUT", "/issue/" + issue.value.issue_id, null, {
      ended_on: ended_on.value,
      final_outcome: final_outcome.value,
    })
    .then(() => {
      messages.value = [{ level: "info", text: default_labels.value.success }];
    });
};

const forecast_instruction = computed((): string => {
  const str = default_labels.value.forecast_with_advance;
  const duration = hours_to_duration(issue.value.delay / 3600);
  const duration_nb = isNaN(duration.value) ? "NaN" : localeStore.relative_datetime_format.format(duration.value, duration.unit);
  return str.replace("{0}", duration_nb);
});

const tag_list = computed((): string[] => {
  const tl = issue.value?.tags;
  if (!tl) return [];
  return tl.split(" ");
});

const removeMessage = function (index: number): void {
  messages.value.splice(index, 1);
};

const outcome_class = function (index: number): string {
  if (issue.value.final_outcome === index) return "chosen";
  return "";
};

const is_closed = computed((): boolean => {
  const fo = issue.value?.final_outcome;
  return fo !== null && fo !== undefined;
});

const issue_window_style = { "max-width": "60em" } as CSSProperties;

const share = (): void => {
  const iss = issue.value;
  if (iss === null || !iss.issue_id) return;

  if (navigator.share) {
    navigator
      .share({
        title: iss.title,
        text: iss.description,
        url: window.location.href + window.location.search,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    toClipboard(window.location.href + window.location.search).then(() => {
      toast(default_labels.value.copied, {
        autoClose: 1000,
      });
    });
  }
};

watch(
  () => route.params.slug,
  (slug) => {
    load_all(firstArg(slug));
  },
  { immediate: true }
);

sessionStore.require_no_session();
</script>

<style scoped>
.issue {
  margin-top: 3rem;
  position: relative;
}

.share {
  position: absolute;
  right: 1.5em;
  font-size: 1.2em;
  cursor: pointer;
}

.issue .loader {
  position: absolute;
  right: 4rem;
  top: 2rem;
  width: 1.5rem;
}

.issue .body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}

.issue .outcomes {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 1.3em;
}

.outcome > * {
  text-shadow: none;
}

.outcome.overdue {
  color: grey;
  font-style: italic;
  cursor: not-allowed;
}

.issue.closed .outcome {
  color: inherit;
  font-style: normal;
}

.issue .outcome.chosen {
  cursor: not-allowed;
}

.issue .outcome .sub {
  font-size: 0.6em;
  font-weight: normal;
}

.issue .outcome.chosen {
  border: 3px solid white;
}

.issue .outcome.success {
  border: 3px solid greenyellow;
}

.issue .outcome.failure {
  border: 3px solid red;
}

.issue .outcome.correction {
  border: 3px solid blue;
}
</style>
