import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons/faShareAlt";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";

import mixpanel from 'mixpanel-browser';
import { createGtm } from '@gtm-support/vue-gtm';

library.add(
  faSearch as IconDefinition, 
  faTimes as IconDefinition,
  faCirclePlus as IconDefinition,
  faCheckCircle as IconDefinition,
  faShareAlt as IconDefinition,
  faGlobe as IconDefinition,
  faUser as IconDefinition,
  faTrash as IconDefinition,
  faCopy as IconDefinition,
);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

mixpanel.init('7c83c9bbacc7035cbea773773b23787e', {debug: true, track_pageview: true, persistence: 'localStorage'})

const gtm = createGtm({
  id: import.meta.env.VITE_GTM,
  debug: true,
  vueRouter: router
});


createApp(App).use(pinia).use(router).use(gtm).provide("mixpanel", mixpanel).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
